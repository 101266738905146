<template>
    <div class="myClass">
        <div class="section">
            <div class="left">老师</div>
            <div class="list">
                <div class="list_item" v-for="item in teacherList" :key="item.id">
                    <img :src="getProfilePath(item.headImg)" />
                    <p>{{ item.realName }}</p>
                </div>
            </div>
        </div>
        <div class="section">
            <div class="left">学生</div>
            <div class="list">
                <div class="list_item" v-for="item in studentList" :key="item.id">
                    <img :src="getProfilePath(item.headImg)" v-if="item.headImg" />
                    <img src="../../static/img/head.png" v-else>
                    <p>{{ item.realName }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { queryCurrentClassesInfo } from "@/api/mine";
export default {
    data() {
        return {
            studentList: [],
            teacherList: [],
        }
    },
    mounted() {
        this.queryCurrentClassesInfo()
    },
    methods: {
        async queryCurrentClassesInfo() {
            const res = await queryCurrentClassesInfo({});
            this.studentList = res.data.studentList;
            this.teacherList = res.data.teacherList;
        }
    },
}
</script>
<style scoped lang="less">
.myClass {
    padding: .35rem 0;

    .section {
        display: flex;

        .left {
            margin: .32rem .86rem 0 0;
            font-size: .18rem;
            font-family: 'PingFang SC';
            font-weight: 400;
            color: #36393D;
            flex-shrink: 0;
        }

        .list {
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            .list_item {
                width: .8rem;
                margin-right: .6rem;
                margin-bottom: .4rem;

                img {
                    width: .8rem;
                    height: .8rem;
                    background: #F7F8F9;
                    border-radius: 50%;
                }

                p {
                    width: .8rem;
                    text-align: center;
                    font-size: .18rem;
                    font-family: 'PingFang SC';
                    font-weight: 400;
                    color: #36393D;
                    margin-top: .2rem;
                }
            }
        }
    }
}
</style>
