<template>
  <div style="width: 100%;" class="container">
    <div class="content" v-if="!registerSuccess">
      <div class="title">- 注册账号 -</div>
      <div class="section" style="margin-top: .56rem;">
        <div class="left">
          <div class="titles">1.选择加入班级</div>
          <div class="msg">请咨询老师获取加入的班级、填写真实姓名，让老师和同学认出你</div>
        </div>
        <div class="right">
          <div class="titles">2.设置账号</div>
          <div class="msg"><span>*</span>至少填写8位字符，必须包含数字和英文字母的组合</div>
        </div>
      </div>
      <div class="section">

        <el-form :model="form1" :rules="rules" ref="ruleForm" class="demo-ruleForm">
          <el-row>
            <el-col :span="15">
              <el-form-item label="" prop="loginName">
                <div class="formItem">
                  <img src="../static/img/icon_sjh.png" class="img">
                  <el-input type="text" placeholder="请输入手机号" v-model="form1.loginName" />
                </div>
              </el-form-item>
              <el-form-item label="" prop="realName">
                <div class="formItem">
                  <img src="../static/img/icon_name.png" class="img">
                  <el-input type="text" placeholder="请输入姓名" v-model="form1.realName" />
                </div>
              </el-form-item>
              <el-form-item label="" prop="className">
                <el-dropdown trigger="click" @command="handleCommand">
                  <div class="formItem">
                    <img src="../static/img/icon_bj.png" class="img">
                    <el-input placeholder="请选择班级" v-model="form1.className" readonly />
                    <img src="../static/img/icon_xl.png" class="xl">
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item v-for="item in canJoinClasses" :key="item.id" :command="item.id">{{ item.name
                      }}</el-dropdown-item>
                    </el-dropdown-menu>
                  </div>
                </el-dropdown>
              </el-form-item>
            </el-col>
            <el-col :span="9">
              <el-form-item label="" prop="loginPwd">
                <div class="formItem">
                  <img src="../static/img/icon_mm.png" class="img">
                  <el-input type="text" placeholder="请输入密码" v-model="form1.loginPwd" show-password />
                </div>
              </el-form-item>
              <el-form-item label="" prop="rePwd">
                <div class="formItem">
                  <img src="../static/img/icon_mm.png" class="img">
                  <el-input type="text" placeholder="请再次输入密码" v-model="form1.rePwd" show-password />
                </div>
              </el-form-item>
              <el-form-item label="" prop="email">
                <div class="formItem">
                  <img src="../static/img/icon_mail.png" class="img">
                  <el-input type="text" placeholder="请输入邮箱，可用于找回密码" v-model="form1.email" />
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="flex">
        <el-button class="next" type="primary" @click="confirm()" :loading="loading">
          <span>下一步</span>
        </el-button>
      </div>
      <p class="msg1" @click="$router.push({ path: '/login' }, onComplete => { }, onAbort => { })">已有账号，直接登录</p>

    </div>
    <div class="content" v-else>
      <div class="title">- 注册账号 -</div>
      <img src="../static/img/icon_cg.png" class="cg">
      <div class="msg2">注册成功，请等待审核，审核通过后方可登录系统</div>
      <div class="flex" style="margin-top: 1.3rem;">
        <el-button class="next" type="primary"
          @click="$router.push({ path: '/login' }, onComplete => { }, onAbort => { })">
          <span>完成</span>
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { register, queryCanJoinClasses } from "@/api/login";
export default {
  data() {
    var checkEmail = (email) => {
      var patten = /^([a-zA-Z0-9]+[_|_|\-|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
      if (!patten.test(email)) {
        return false;
      } else {
        return true;
      }
    }
    var isEmail = (rule, value, callback) => {
      if (!value) {
        callback()
      } else {
        const email = checkEmail(value)
        if (!email) {
          callback(new Error('邮箱格式不正确'))
        } else {
          callback()
        }
      }
    };
    var checkPasswords = (psd) => {
      var patten = /^(?![0-9]+$)(?![a-zA-Z]+$)(?!(1|[\(\)])+$)([^(0-9a-zA-Z)]|[\\(\\)]|[a-zA-Z]|[0-9]){8,20}$/;
      if (!patten.test(psd)) {
        return false;
      } else {
        return true;
      }
    }
    var isPassword = (rule, value, callback) => {
      if (!value) {
        callback()
      } else {
        const psd = checkPasswords(value)
        if (!psd) {
          callback(new Error('密码格式不正确'))
        } else {
          callback()
        }
      }
    };
    return {
      loading: false,
      rules: {
        loginName: [
          { required: true, message: '请输入手机号', trigger: ['blur', 'change'] }
        ],
        realName: [
          { required: true, message: '请输入姓名', trigger: ['blur', 'change'] }
        ],
        className: [
          { required: true, message: '请选择班级', trigger: ['blur', 'change'] }
        ],
        loginPwd: [
          { required: true, message: '请输入密码', trigger: ['blur', 'change'] },
          { validator: isPassword, trigger: ['blur', 'change'] },
        ],
        rePwd: [
          { required: true, message: '请再次输入密码', trigger: ['blur', 'change'] },
          { validator: this.checkPassword, trigger: ['blur', 'change'] },
        ],
        email: [
          { required: true, message: '请输入邮箱', trigger: ['blur', 'change'] },
          { validator: isEmail, trigger: ['blur', 'change'] }
        ]
      },
      form1: {
        loginName: '',
        realName: '',
        className: '',
        loginPwd: '',
        rePwd: '',
        email: ''
      },
      canJoinClasses: [],
      registerSuccess: false
    }
  },
  mounted() {
    this.queryCanJoinClasses()
  },
  methods: {
    handleCommand(id) {
      this.canJoinClasses.forEach((d) => {
        if (d.id == id) {
          this.form1.classesId = id;
          this.form1.className = d.name;
        }
      })
    },
    async queryCanJoinClasses() {
      const res = await queryCanJoinClasses({});
      this.canJoinClasses = res.data
    },
    checkPassword(rule, value, callback) {
      if (!value) {
        callback()
      } else {
        if (this.form1.loginPwd != this.form1.rePwd) {
          callback(new Error('两次密码不一致'))
        } else {
          callback()
        }
      }
    },
    confirm() {
      this.$refs['ruleForm'].validate(async (valid) => {
        if (valid) {
          let data = {
            classesId: this.form1.classesId,
            email: this.form1.email,
            loginPwd: this.form1.loginPwd,
            phone: this.form1.loginName,
            realName: this.form1.realName
          }
          try {
            this.loading = true;
            let res = await register(data);
            if (res.code == 200) {
              this.$message.success('注册成功');
              this.registerSuccess = true
            }
            this.loading = false;
          } catch (error) {
            this.loading = false;
          }

        } else {
          return false;
        }
      });
    }
  }
}
</script>
<style>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: Microsoft YaHei;
}
</style>
<style lang="less" scoped>
::v-deep .el-dropdown-menu__item {
  width: 4.4rem !important;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  width: 100%;
  height: 100vh;
  background-image: url(../static/img/bgi.png);
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .content {
    width: 13.35rem;
    height: 7.5rem;
    background: #FFFFFF;
    box-shadow: 0rem 0rem 1.14rem 0rem #D2D2D2;
    border-radius: .2rem;
    padding: .49rem 1.58rem;
    box-sizing: border-box;

    .title {
      width: 100%;
      font-size: .34rem;
      font-family: pingfang;
      font-weight: 500;
      color: #4C88EB;
      text-align: center;
      user-select: none;
    }

    .section {
      display: flex;

      .titles {
        font-size: .2rem;
        font-family: 'PingFang SC';
        font-weight: 500;
        color: #36393D;
      }

      .msg {

        font-size: .16rem;
        color: #6D737A;
        margin-top: .19rem;
        margin-bottom: .3rem;

        span {
          color: #F20000;
        }
      }

      .formItem {
        position: relative;
        margin-top: .08rem;
        width: 4.4rem;
        height: .72rem;

        .img {
          width: .24rem;
          height: .24rem;
          position: absolute;
          z-index: 999;
          left: .26rem;
          top: 50%;
          transform: translateY(-50%);
        }

        .xl {
          position: absolute;
          z-index: 999;
          right: .26rem;
          top: 50%;
          transform: translateY(-50%);
        }

        ::v-deep .el-input__inner {
          width: 4.4rem;
          height: .72rem;
          background: #F7F8F9;
          border-radius: .1rem;
          border: 0;
          outline: 0;
          padding-left: .67rem;
          padding-right: .26rem;
          font-size: .18rem;

          &::placeholder {
            color: #BEC2C7;
          }
        }
      }

      .left {
        width: 5.8rem;

      }

      .right {}

    }

    .next {
      width: 4.4rem;
      height: .72rem;
      background: linear-gradient(90deg, #4C88EB, #3D70E7);
      border-radius: .36rem;
      font-size: .2rem;
      text-align: center;
      color: #FFFFFF;
      margin: .3rem auto .2rem;
      cursor: pointer;
      transition: filter 0.3s;

      &:hover {
        filter: brightness(1.1);
      }
    }

    .cg {
      width: 1.1rem;
      height: 1.1rem;
      margin: 1.64rem auto 0;
      display: block;
    }

    .msg1 {
      width: 100%;
      text-align: center;
      font-size: .18rem;
      cursor: pointer;
      color: #4C88EB;
      transition: filter 0.3s;

      &:hover {
        filter: brightness(1.1);
      }
    }

    .msg2 {
      width: 100%;
      text-align: center;
      font-size: .18rem;
      color: #36393D;
      margin-top: .29rem;
    }
  }
}
</style>
