
import request from '../request'

export function updateStudentInfo(data) {
    return request({
        url: '/prod-api/api/studentCenter/updateStudentInfo',
        method: 'post',
        data: data
    })
}
export function queryCurrentClassesInfo(params) {
    return request({
        url: '/prod-api/api/studentCenter/queryCurrentClassesInfo',
        method: 'get',
        params: params
    })
}
export function getLoginStudentInfo(params) {
    return request({
        url: '/prod-api/api/studentCenter/getLoginStudentInfo',
        method: 'get',
        params: params
    })
}
export function upload(data) {
    return request({
        url: '/prod-api/api/common/upload',
        method: 'post',
        data: data
    })
}
export function queryStudentClassesSubjectList(params) {
    return request({
        url: '/prod-api/api/student/queryStudentClassesSubjectList',
        method: 'get',
        params: params
    })
}
export function queryCollectSubjectList(params) {
    return request({
        url: '/prod-api/api/student/queryCollectSubjectList',
        method: 'get',
        params: params
    })
}
export function queryStudentTaskList(params) {
    return request({
        url: '/prod-api/api/studentHome/queryStudentTaskList',
        method: 'get',
        params: params
    })
}
export function queryStudentExamList(params) {
    return request({
        url: '/prod-api/api/studentHome/queryStudentExamList',
        method: 'get',
        params: params
    })
}
export function queryStudentIntroduce(params) {
    return request({
        url: '/prod-api/api/studentCenter/queryStudentIntroduce',
        method: 'get',
        params: params
    })
}
export function saveStudentIntroduce(data) {
    return request({
        url: '/prod-api/api/studentCenter/saveStudentIntroduce',
        method: 'post',
        data: data
    })
}
export function queryStudentEvaluateList(params) {
    return request({
        url: '/prod-api/api/studentCenter/queryStudentEvaluateList',
        method: 'get',
        params: params
    })
}
export function changePwd(params) {
    return request({
        url: '/prod-api/api/studentCenter/changePwd',
        method: 'get',
        params: params
    })
}
export function changeEmail(params) {
    return request({
        url: '/prod-api/api/studentCenter/changeEmail',
        method: 'get',
        params: params
    })
}
export function changePhone(params) {
    return request({
        url: '/prod-api/api/studentCenter/changePhone',
        method: 'get',
        params: params
    })
}
export function loginOut(data) {
    return request({
        url: '/prod-api/api/common/loginOut',
        method: 'post',
        data: data
    })
}
