<template>
    <div style="width: 100%;" class="lessonDetail">
        <header>
            <homeHeader :active="2" />
        </header>
        <div class="top">
            <img src="../static/img/icon_back.png" @click="back">
            <span>{{ name }}</span>
        </div>
        <div class="content">
            <div class="title">{{ subjectInfo.name }}</div>
            <div class="introduce" :title="subjectInfo.introduce">
                {{ subjectInfo.introduce }}
            </div>
            <div class="teacher">
                讲师：{{ subjectInfo.teacherNames }}老师
            </div>
            <section>
                <div class="list"
                    :style="subjectInfo.catalogueList && subjectInfo.catalogueList.length > 7 ? 'overflow-y: scroll;' : ''">
                    <div class="list_item" v-for="item in subjectInfo.catalogueList" :key="item.id">
                        <template v-if="item.knowledgeList.length > 0 && item.knowledgeList[0].type == 1">
                            <div class="secondTitle" v-for="item2 in item.knowledgeList" :key="item2.id">
                                <div class="l">{{ item2.name }}</div>
                                <div class="r">
                                    <div v-for="item3 in item2.contentList" :key="item3.id">
                                        <div class="pdf" v-if="item3.type == 1" @click="toInfo(item3)">
                                            <div class="img"></div>
                                            <span>PDF</span>
                                        </div>
                                        <div class="sp" v-if="item3.type == 2" @click="toInfo(item3)">
                                            <div class="img"></div>
                                            <span>视频</span>
                                        </div>
                                        <div class="wb" v-if="item3.type == 3" @click="toInfo(item3)">
                                            <div class="img"></div>
                                            <span>文本</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
                <div class="right">
                    <iframe :src="url" frameborder="0" style="width: 100%; height: 100%" v-if="type == 1"></iframe>
                    <video controls :src="url" v-if="type == 2"></video>
                    <div class="fwb" v-html="content" v-if="type == 3"></div>
                </div>
            </section>
        </div>
        <homeFooter />
    </div>
</template>

<script>
import homeHeader from '@/components/homeHeader.vue'
import homeFooter from '@/components/homeFooter.vue'
import { querySubjectInfo, } from "@/api/home";
import pdf from 'vue-pdf'
export default {
    components: {
        homeHeader, homeFooter, pdf
    },
    data() {
        return {
            id: '',
            name: '',
            type: '',
            url: '',
            numPages: null,
            content: '',
            subjectInfo: {},
        }
    },
    mounted() {
        this.id = this.$route.query.id;
        this.querySubjectInfo(this.id)
        this.type = this.$route.query.type;
        this.name = this.$route.query.name;
        switch (this.type) {
            case '1':
                this.url = 'https://eeapi.appcloud.tech/prod-api' + this.$route.query.url;
                this.getNumPages()
                console.log(11);
                break;
            case '2':
                this.url = "https://eeapi.appcloud.tech/prod-api" + this.$route.query.url
                break;
            case '3':
                this.content = sessionStorage.getItem('edu_content')
                break;
        }
    },
    beforeDestroy() {
    },
    methods: {
        back() {
            window.history.go(-1);
            setTimeout(() => {
                if (this.$route.path == '/lessonInfo') {
                    window.location.reload()
                }
            }, 100);
        },
        async querySubjectInfo(id) {
            let res = await querySubjectInfo({ id })
            if (res.code == 200) {
                this.subjectInfo = res.data;
            }
        },
        toInfo(item) {
            if (item.name == this.name) {
                window.location.reload()
            } else {
                switch (item.type) {
                    case 1:
                        this.$router.push({
                            path: '/lessonInfo',
                            query: {
                                id: this.id,
                                type: 1,
                                name: item.name,
                                url: item.url
                            }
                        })
                        break;
                    case 2:
                        this.$router.push({
                            path: '/lessonInfo',
                            query: {
                                id: this.id,
                                type: 2,
                                name: item.name,
                                url: item.url
                            }
                        })
                        break;
                    case 3:
                        sessionStorage.setItem('edu_content', item.content)
                        this.$router.push({
                            path: '/lessonInfo',
                            query: {
                                id: this.id,
                                type: 1,
                                name: item.name
                            }
                        })
                        break;
                }
                window.location.reload()
            }
        },
        getNumPages() {
            let loadingTask = pdf.createLoadingTask(this.url)
            loadingTask.promise.then(pdf => {
                this.numPages = pdf.numPages;
            }).catch(err => {
                console.error('pdf 加载失败', err);
            })
        },
    }
}
</script>
<style scoped lang="less">
::v-deep .el-dialog {
    border-radius: .16rem;
}

header {
    width: 100%;
    height: .92rem;
}

.top {
    width: 100%;
    height: .99rem;
    background: #FFFFFF;
    display: flex;
    align-items: center;

    img {
        width: .24rem;
        height: .24rem;
        margin: 0 .32rem 0 .49rem;
        cursor: pointer;
    }

    span {
        font-size: .2rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #2D2D2D;
    }
}

.content {
    width: 100%;
    padding: .5rem .5rem .86rem .5rem;
    background: #1C1F21;

    .title {

        font-size: .36rem;
        font-family: PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
    }

    .introduce {

        font-size: .18rem;
        font-family: PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: .4rem;
        margin: .2rem 0 .3rem;
    }

    .teacher {
        font-size: .18rem;
        font-family: PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
    }

    section {
        margin-top: .3rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .list {
            width: 23.4%;
            height: 8.04rem;
            background: #292D2F;
            border-radius: .16rem;


            .list_item {
                padding: 0 .3rem;

                .firstTitle {
                    width: 100%;
                    height: .89rem;
                    line-height: .89rem;
                    font-size: .21rem;
                    font-family: PingFang SC;
                    font-weight: 700;
                    color: #36393D;
                    border-bottom: .01rem solid #eee;
                }

                .secondTitle {
                    width: 100%;
                    height: 1.34rem;
                    font-size: .2rem;
                    font-family: PingFang SC;
                    font-weight: 500;
                    border-bottom: .01rem solid rgba(255, 255, 255, 0.1);
                    color: #fff;
                    padding-top: .35rem;

                    .r {
                        font-size: .18rem;
                        font-family: PingFang SC;
                        font-weight: 400;
                        margin-top: .25rem;
                        color: #fff;

                        >div {
                            display: flex;
                            align-items: center;
                        }

                        .pdf {
                            display: flex;
                            align-items: center;
                            cursor: pointer;
                            margin-left: .2rem;

                            .img {
                                width: .26rem;
                                height: .26rem;
                                margin-right: .05rem;
                                background-image: url(../static/img/icon_pdf.png);
                                background-size: 100% 100%;
                            }

                            &:hover {
                                .img {
                                    background-image: url(../static/img/icon_pdf_s.png);
                                }

                                color: #FF4720;
                            }
                        }

                        .sp {
                            display: flex;
                            align-items: center;
                            cursor: pointer;
                            margin-left: .2rem;

                            .img {
                                width: .26rem;
                                height: .26rem;
                                margin-right: .05rem;
                                background-image: url(../static/img/icon_sp2.png);
                                background-size: 100% 100%;
                            }

                            &:hover {
                                .img {
                                    background-image: url(../static/img/icon_sp_s.png);
                                }

                                color: #FF7800;
                            }
                        }

                        .wb {
                            display: flex;
                            align-items: center;
                            cursor: pointer;
                            margin-left: .2rem;

                            .img {
                                width: .26rem;
                                height: .26rem;
                                margin-right: .05rem;
                                background-image: url(../static/img/icon_fwb2.png);
                                background-size: 100% 100%;
                            }

                            &:hover {
                                .img {
                                    background-image: url(../static/img/icon_fwb_s.png);
                                }

                                color: #4C88EB;
                            }
                        }
                    }
                }
            }
        }

        .right {
            width: 74.9%;
            height: 8.04rem;
            background: #080909;
            border-radius: .16rem;
            padding: .2rem;
            box-sizing: border-box;

            iframe {
                border-radius: .16rem;
            }

            video {
                width: 100%;
                height: 100%;
                border-radius: .16rem;
            }

            .fwb {
                width: 100%;
                height: 100%;
                border-radius: .16rem;
                overflow-y: scroll;
                background: #fff;
            }
        }
    }
}
</style>

