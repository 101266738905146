<template>
  <div>
    <footer>
      <div class="text">
        <span class="nav" @click="toSet()">个人信息</span>
        <span class="nav" @click="toSet()">账号安全</span>
        <span class="nav" @click="toSet()">关于我们</span>
      </div>
      <div class="text" style="margin: .3rem 0 0 0;">
        <span style="margin-right: .15rem;">网上有害信息举报 (涉未成年人)网站 : <a
            href="https://www.12377.cn">https://www.12377.cn</a></span>
        <span>邮箱 (涉未成年人) : youdao _jubao@rd.netease.com</span>
      </div>
      <!-- <div class="text">
        <span>粤B2-20090191-26|京ICP备12020869号-2|京公网安备44010602000207</span>
        <span style="margin-left: .16rem;">@2014-2023 icourse163.org</span>
        <img src="" alt="" class="icon">
        <span>浙公网安备 33010802012594号</span>
      </div> -->
    </footer>
  </div>
</template>

<script>
import { getToken } from '@/api/auth'
export default {
  name: 'homeFooter',
  data() {
    return {
      isLogin: false
    }
  },
  mounted() {
    let token = getToken()
    if (token) {
      this.isLogin = true
    } else {
      this.isLogin = false
    }
  },
  methods: {
    toSet() {
      if (!this.isLogin) {
        this.$message.error('请先登录')
        setTimeout(() => {
          this.$router.push({ path: '/login' }, onComplete => { }, onAbort => { })
        }, 1500);
      } else {
        this.$router.push({
          path: '/userCenter', query: {
            nav: 6
          }
        },
          onComplete => {
            if (this.$route.path == '/userCenter') {
              window.location.reload()
            }
          },
          onAbort => { })
      }
    }
  },
}
</script>
<style>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
</style>
<style scoped lang="less">
footer {
  width: 100%;
  height: 1.5rem;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  padding-left: 2.23rem;

  .text {
    width: 100%;
    font-size: .17rem;
    color: #979ea7;
    display: flex;
    align-items: center;

    a {
      color: #979ea7;
      text-decoration: none;
      transition: all 0.3s;

      &:hover {
        color: #000;
      }
    }

    .icon {
      width: .19rem;
      height: .2rem;
      margin: 0 .13rem 0 .38rem;
    }

    .nav {
      cursor: pointer;
      margin-right: .38rem;
      transition: all 0.3s;

      &:hover {
        color: #000;
      }
    }
  }
}
</style>
