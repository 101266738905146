<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  created() {
    this.rem()
  },
  methods: {
    rem() {
      /**
* px转换rem单位
* px转换rem(设备的宽度除以15（750 / 15 = 50）  1rem = 50px;)
* */

      (function (win) {
        var ratio, scaleValue, renderTime,
          document = window.document,
          docElem = document.documentElement,
          vpm = document.querySelector('meta[name="viewport"]');
        if (vpm) {
          var tempArray = vpm.getAttribute("content").match(/initial\-scale=(["']?)([\d\.]+)\1?/);
          if (tempArray) {
            scaleValue = parseFloat(tempArray[2]);
            ratio = parseInt(1 / scaleValue);
          }
        } else {
          vpm = document.createElement("meta");
          vpm.setAttribute("name", "viewport");
          vpm.setAttribute("content", "initial-scale=1,user-scalable=no,minimal-ui");
          docElem.firstElementChild.appendChild(vpm);
        }

        win.addEventListener("resize", function () {
          clearTimeout(renderTime);
          renderTime = setTimeout(initPage, 300);
        }, false);

        win.addEventListener("pageshow", function (e) {
          e.persisted && (clearTimeout(renderTime), renderTime = setTimeout(initPage, 300));
        }, false);

        "complete" === document.readyState ? document.body.style.fontSize = 12 * ratio + "px" :
          document.addEventListener("DOMContentLoaded", function () {
            document.body.style.fontSize = 12 * ratio + "px";
          }, false);
        initPage();
        //设备的宽度除以15（750 / 15 = 50）  1rem = 50px;
        function initPage() {
          var htmlWidth = docElem.getBoundingClientRect().width;
          //		if(htmlWidth>750) htmlWidth=750;
          //		htmlWidth/ratio>750 && (htmlWidth=750*ratio);
          win.rem = htmlWidth / 120 * 6.25;
          docElem.style.fontSize = win.rem + "px";

          /********适配曲面屏********/
          var html = document.getElementsByTagName('html')[0];
          var settedFs = parseInt(html.style.fontSize);
          var realFs = parseInt(window.getComputedStyle(html).fontSize);
          var whileCount = 0;
          while (true) {
            var realFs = parseInt(window.getComputedStyle(html).fontSize);
            var delta = realFs - settedFs;
            console.log(settedFs, realFs)
            if (Math.abs(delta) != 0) //不相等
            {
              html.setAttribute('style', 'font-size:' + settedFs * (settedFs / realFs) + 'px !important');
              //settedFs/realFs 计算比实际值小N倍  然后在原基础上在放大N倍
            } else
              break;
            if (whileCount++ > 100) //为了避免死循环
              break
          }
        }
      })(window);

      // 禁止微信调整字体大小
      (function () {
        if (typeof WeixinJSBridge == "object" && typeof WeixinJSBridge.invoke == "function") {
          handleFontSize();
        } else {
          if (document.addEventListener) {
            document.addEventListener("WeixinJSBridgeReady", handleFontSize, false);
          } else if (document.attachEvent) {
            document.attachEvent("WeixinJSBridgeReady", handleFontSize);
            document.attachEvent("onWeixinJSBridgeReady", handleFontSize);
          }
        }

        function handleFontSize() {
          // 设置网页字体为默认大小
          WeixinJSBridge.invoke('setFontSizeCallback', { 'fontSize': 0 });
          // 重写设置网页字体大小的事件
          WeixinJSBridge.on('menu:setfont', function () {
            WeixinJSBridge.invoke('setFontSizeCallback', { 'fontSize': 0 });
          });
        }
      })();
    }
  }
}
</script>

<style></style>