import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import VueClipBoard from 'vue-clipboard2'
import 'element-ui/lib/theme-chalk/index.css';
import './static/font/iconfont.css'

Vue.prototype.getProfilePath = function (img) {
  return 'https://eeapi.appcloud.tech' + img
}
Vue.prototype.getStaticPath = function (img) {
  return 'https://eeapi.appcloud.tech/profile/static' + img
}
Vue.use(VueClipBoard)
Vue.use(ElementUI)
Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
}).$mount('#app')
