<template>
    <div style="width: 100%;" class="workDetail">
        <header>
            <homeHeader :active="2" />
        </header>
        <div class="content">
            <div class="back" @click="back()">
                <img src="../static/img/icon_back2.png">返回
            </div>
            <div class="section">
                <div class="swiper-item">
                    <div class="title">
                        <span>{{ currentNum }}.</span>
                        <div class="icon">
                            <span v-if="questionList[currentNum - 1].type == 1">单选</span>
                            <span v-if="questionList[currentNum - 1].type == 2">多选</span>
                            <span v-if="questionList[currentNum - 1].type == 3">判断</span>
                            <span v-if="questionList[currentNum - 1].type == 4">填空</span>
                            <span v-if="questionList[currentNum - 1].type == 5">问答</span>
                        </div>
                        <div v-html="questionList[currentNum - 1].content"></div>
                    </div>
                    <div class="questionItemList">
                        <div v-if="questionList[currentNum - 1].type == 1 || questionList[currentNum - 1].type == 3">
                            <div class="danxuan" v-for="item2 in questionList[currentNum - 1].questionItemList"
                                :key="item2.id" @click="changeDanXuan(questionList[currentNum - 1].id, item2.id)">
                                <div :class="item2.checked ? 'activeItemTag' : 'itemTag'">
                                </div>
                                {{ item2.itemTag }}.
                                <div style="margin-left: .05rem;" v-html="item2.itemContent"></div>
                            </div>
                        </div>
                        <div v-if="questionList[currentNum - 1].type == 2">
                            <div class="danxuan" v-for="item2 in questionList[currentNum - 1].questionItemList"
                                :key="item2.id" @click="changeDuoXuan(questionList[currentNum - 1].id, item2.id)">
                                <div :class="item2.checked ? 'activeItemTag' : 'itemTag'">
                                </div>
                                {{ item2.itemTag }}.
                                <div style="margin-left: .05rem;" v-html="item2.itemContent"></div>
                            </div>
                        </div>
                        <div v-if="questionList[currentNum - 1].type == 4">
                            <div class="danxuan" v-for="(item2, i) in JSON.parse(questionList[currentNum - 1].rightAnswer)"
                                :key="i">
                                <div class="inpit_" @click="ids = i; QID = questionList[currentNum - 1].id">
                                    <input type="span" @input="tiankong"
                                        :value="questionList[currentNum - 1].questionItemList[i]" />
                                </div>
                            </div>
                        </div>
                        <div v-if="questionList[currentNum - 1].type == 5">
                            <div class="wenda" @click="QID = questionList[currentNum - 1].id">
                                <input type="textarea" placeholder="请输入内容" @input="wenda"
                                    :value='questionList[currentNum - 1].questionItemList' />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="bottom">
                <div v-if="currentNum == 1 && questionNum != 1">
                    <div class="next1" @click="next()">下一题</div>
                </div>
                <div class="btn_box" v-if="currentNum != 1 && currentNum != questionNum && questionNum != 1">
                    <div class="exam_btn" @click="pre()">上一题</div>
                    <div class="exam_btn" @click="next()">下一题</div>
                </div>
                <div class="btn_box" v-if="currentNum == questionNum && questionNum != 1">
                    <div class="exam_btn" @click="pre()">上一题</div>
                    <div class="exam_btn" @click="showConfirmModal = true">提交</div>
                </div>
                <div class="btn_box" v-if="questionNum == 1">
                    <div class="next1" @click="showConfirmModal = true">提交</div>
                </div>
            </div>
        </div>
        <el-dialog title="" :visible.sync="showConfirmModal" width="30%">
            <h2 style="width: 100%;text-align: center;">提交后本次作业将不可修改，确定要提交?</h2>
            <div class="btn2">
                <div class="qx" @click="showConfirmModal = false">取消</div>
                <el-button class="qd" type="primary" @click="confirm()">
                    确定
                </el-button>
            </div>
        </el-dialog>
        <homeFooter style="margin-top: .8rem;" />
    </div>
</template>

<script>
import homeHeader from '@/components/homeHeader.vue'
import homeFooter from '@/components/homeFooter.vue'
import { queryStudentTaskInfo, submitDailyTask } from "@/api/home";
export default {
    components: {
        homeHeader, homeFooter
    },
    data() {
        return {
            ids: '',
            id: '',
            questionId: '',
            time: '',
            count: '',
            showModal: false,
            showConfirmModal: false,
            submit: false,
            correction: 0,
            questionNum: '',
            currentNum: 1,
            questionList: [],
            QID: '',
            itemTag: [],
            answer: {},
            tiankongs: {},
        }
    },
    mounted() {
        this.id = this.$route.query.id
        this.correction = this.$route.query.correction
        this.queryStudentTaskInfo(this.id)
    },
    beforeDestroy() {
    },
    methods: {
        back() {
            window.history.go(-1);
        },
        tiankong(e) {
            let num = e.target.keyCode;
            this.tiankongs[this.ids] = e.target.value;
            this.itemTag = []
            for (let i in this.tiankongs) {
                this.itemTag.push(this.tiankongs[i])
            }
        },
        wenda(e) {
            this.itemTag = [e.target.value];
        },
        changeDanXuan(id1, id2) {
            this.questionList.forEach((d) => {
                if (d.id == id1) {
                    d.questionItemList.forEach((e) => {
                        if (e.id != id2) {
                            e.checked = false
                        } else {
                            e.checked = true;
                            this.itemTag = [e.itemTag]
                        }
                    })
                }
            })
            this.QID = id1;
            this.$forceUpdate()
        },
        changeDuoXuan(id1, id2) {
            this.questionList.forEach((d) => {
                if (d.id == id1) {
                    d.questionItemList.forEach((e) => {
                        if (e.id == id2) {
                            e.checked = !e.checked;
                            if (e.checked) {
                                this.itemTag.push(e.itemTag)
                            } else {
                                this.itemTag.splice(this.itemTag.findIndex(item => item == e.itemTag),
                                    1)
                            }
                        }
                    })
                }
            })
            this.QID = id1;
            this.$forceUpdate()
        },
        async queryStudentTaskInfo(id) {
            let res = await queryStudentTaskInfo({
                id
            })
            if (res.code == 200) {
                if (this.correction == 1) {
                    let correctionList = [];
                    res.data.questionList.forEach((d) => {
                        if (d.isRight == 2) {
                            correctionList.push(d)
                        }
                    })
                    this.questionList = correctionList;
                } else {
                    this.questionList = res.data.questionList;
                }
                this.questionNum = this.questionList.length;
                this.questionId = res.data.id;
                if (this.correction == 0 || this.correction == 2) {
                    this.questionList.forEach((d) => {
                        if (d.status == 1) {
                            if (d.type == 1 || d.type == 3) { //单选和判断
                                let studentAnswer = JSON.parse(d.studentAnswer);
                                d.questionItemList.forEach((a) => {
                                    if (a.itemTag == studentAnswer[0]) {
                                        a.checked = true
                                    }
                                })
                            } else if (d.type == 2) { //多选
                                let studentAnswer = JSON.parse(d.studentAnswer);
                                studentAnswer.forEach((e) => {
                                    d.questionItemList.forEach((a) => {
                                        if (a.itemTag == e) {
                                            a.checked = true
                                        }
                                    })
                                })
                                this.itemTag = studentAnswer
                            } else if (d.type == 4) { //填空
                                let studentAnswer = JSON.parse(d.studentAnswer);
                                d.questionItemList = studentAnswer;
                            } else if (d.type == 5) { //问答
                                let studentAnswer = JSON.parse(d.studentAnswer);
                                d.questionItemList = studentAnswer[0];
                            }
                        }
                    })
                }
            }
        },
        async answerTesting(answer, questionId) {
            let res = await submitDailyTask({
                answer: answer,
                id: this.questionId,
                classesTaskQuestionId: questionId,
                submit: this.submit
            })

            if (res.code == 200) {
                if (this.correction == 0) {
                    this.queryStudentTaskInfo(this.id);
                }
                let num = this.currentNum;
                this.questionList[num - 1].done = true;
                if (this.currentNum != this.questionNum) {
                    this.currentNum++;
                    this.QID = '';
                    this.itemTag = [];
                } else {
                    this.$router.replace({
                        path: '/workDetail',
                        query: {
                            id: this.id
                        }
                    })
                }
            }
        },
        next() {
            let num = this.currentNum;
            if (this.correction == 2 && this.itemTag.length > 0) {
                this.QID = this.questionList[num - 1].id;
                this.answerTesting([...this.itemTag], this.QID);
            } else if (this.itemTag.length > 0 && this.questionList[num - 1].isRight != 1) {
                if (!this.QID) {
                    this.QID = this.questionList[num - 1].id;
                }
                this.answerTesting([...this.itemTag], this.QID);
            } else if ((this.questionList[num - 1].status == 1 && this.questionList[num - 1].isRight != 2) ||
                (this.questionList[num - 1].status == 1 && this.correction == 0) ||
                this.correction == 1 && this.questionList[num - 1].done == true
            ) {
                // if (this.currentNum != this.questionNum) {
                // 	this.currentNum++;
                // } else {
                // 	uni.redirectTo({
                // 		url: '/components/work/workDetail?id=' + this.id
                // 	})
                // }
                // if (this.correction == 0) {
                // 	this.queryStudentTaskInfo(this.id);
                // }
                if (this.itemTag.length == 0) {
                    let studentAnswer = JSON.parse(this.questionList[num - 1].studentAnswer)
                    this.itemTag = studentAnswer
                }
                if (!this.QID) {
                    this.QID = this.questionList[num - 1].id;
                }
                this.answerTesting([...this.itemTag], this.QID);
            } else {
                this.$message.error('请先作答完当前题')
            }
        },
        pre() {
            this.currentNum--;
            this.itemTag = [];
            if (this.correction == 0) {
                this.queryStudentTaskInfo(this.id);
            }
        },
        confirm() {
            this.showConfirmModal = false;
            this.submit = true;
            this.next()
        }

    }
}
</script>
<style scoped lang="less">
header {
    width: 100%;
    height: .92rem;
}

.workDetail {
    background: #f5f6f8;
    width: 100%;
    padding-top: .3rem;
    box-sizing: border-box;
    overflow: hidden;
}

.btn2 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: .3rem;

    .qx {
        width: 1.43rem;
        height: .48rem;
        border: .01rem solid #DDDDDD;
        border-radius: .24rem;
        font-size: .18rem;
        font-family: 'PingFang SC';
        font-weight: 400;
        color: #36393D;
        text-align: center;
        line-height: .48rem;
        margin: 0 .15rem;
        cursor: pointer;
        transition: filter 0.3s;

        &:hover {
            filter: brightness(1.01);
        }
    }

    .qd {
        width: 1.43rem;
        height: .48rem;
        background: linear-gradient(90deg, #4C88EB, #3D70E7);
        border-radius: .24rem;
        font-size: .18rem;
        font-family: 'PingFang SC';
        font-weight: 400;
        color: #FFFFFF;
        text-align: center;
        margin: 0 .15rem;
        cursor: pointer;
        transition: filter 0.3s;

        &:hover {
            filter: brightness(1.1);
        }
    }
}

.content {
    width: 14.8rem;
    height: 6.68rem;
    margin: auto;
    padding-bottom: .5rem;
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: 0px 0px .2rem 0px rgba(0, 0, 0, 0.16);
    border-radius: .16rem;
    overflow: hidden;
    position: relative;

    .back {
        font-size: .18rem;
        font-family: PingFang SC;
        font-weight: 400;
        color: #979EA7;
        display: flex;
        align-items: center;
        margin: .21rem 0 0 .48rem;
        cursor: pointer;

        img {
            width: .2rem;
            height: .2rem;
            margin-right: .08rem;
        }
    }

    .section {
        padding: .41rem .6rem;

        .swiper-item {
            width: 100%;

            .title {
                display: flex;
                align-items: center;
                font-size: .2rem;
                font-family: PingFang SC;
                font-weight: 700;
                color: #36393D;

                .icon {
                    width: .58rem;
                    height: .32rem;
                    line-height: .32rem;
                    border: 1px solid #4C88EB;
                    border-radius: .1rem;
                    font-size: .16rem;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #4C88EB;
                    text-align: center;
                    margin: 0 .1rem;
                }
            }

            .questionItemList {
                margin-top: 30px;

                .danxuan {
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    margin-top: 10px;
                    font-size: .2rem;
                    font-family: PingFang SC;
                    font-weight: 500;
                    color: #36393D;

                    .inpit_ {
                        border: 1px solid #666;
                        width: 80%;
                        margin-top: 10px;
                    }
                }

                .wenda {
                    max-width: 80vw;
                    min-height: 400px;
                    border: 1px solid #666;
                }

                .itemTag {
                    cursor: pointer;
                    width: .25rem;
                    height: .25rem;
                    background: #FFFFFF;
                    border: .01rem solid #DDDDDD;
                    border-radius: 50%;
                    margin: .1rem .2rem .1rem 0;
                }

                .activeItemTag {
                    cursor: pointer;
                    width: .25rem;
                    height: .25rem;
                    background: #FFFFFF;
                    border: .01rem solid transparent;
                    border-radius: 50%;
                    margin: .1rem .2rem .1rem 0;
                    background-image: url(../static/img/xz2.png);
                    background-size: 100% 100%;
                }
            }
        }

    }

    .bottom {
        height: 157px;
        width: 100%;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: .5rem;
        left: 0;

        .next1 {
            font-size: .2rem;
            font-family: PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            width: 3.7rem;
            height: .72rem;
            background: linear-gradient(90deg, #4C88EB, #3D70E7);
            border-radius: .36rem;
            text-align: center;
            line-height: .72rem;
            cursor: pointer;
            transition: filter 0.3s;

            &:hover {
                filter: brightness(1.1);
            }
        }

        .btn_box {
            display: flex;

            .exam_btn {
                font-size: .2rem;
                font-family: PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                width: 3.7rem;
                height: .72rem;
                background: linear-gradient(90deg, #4C88EB, #3D70E7);
                border-radius: .36rem;
                text-align: center;
                line-height: .72rem;
                cursor: pointer;
                transition: filter 0.3s;
                margin: 0 .1rem;

                &:hover {
                    filter: brightness(1.1);
                }
            }
        }

    }


    .btn {

        width: 4.4rem;
        height: .72rem;
        line-height: .72rem;
        background: linear-gradient(90deg, #4C88EB, #3D70E7);
        border-radius: .36rem;
        font-size: .2rem;
        span-align: center;
        color: #FFFFFF;
        margin: .5rem auto 0;
        cursor: pointer;
        transition: filter 0.3s;

        &:hover {
            filter: brightness(1.1);
        }
    }
}
</style>

