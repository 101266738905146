<template>
    <div style="width: 100%;" class="home">
        <header>
            <homeHeader :active="2" />
        </header>
        <div class="content">
            <div class="title">
                <img src="../static/img/icon_kclb.png">
                <span>我的课程</span>
            </div>
            <div class="list">
                <div class="list_item" v-for="item, index in lessonList" :key="index" @click="toDetail(item.id)">
                    <img :src="'https://eeapi.appcloud.tech' + item.imageUrl" class="photo">
                    <div>
                        <div class="name">{{ item.name }}</div>
                        <div class="teacher">{{ item.teacherNames }}老师</div>
                        <div class="text">
                            <div class="left">已学{{ item.finishChapterNum }}/{{ item.chapterNum }}小节</div>
                            <div class="right" v-if="item.status == 2">{{ item.sumScore }}分</div>
                        </div>
                        <div class="bottom" v-if="item.finish">
                            <img src="../static/img/icon_time.png" alt="">
                            {{ item.finish }}
                        </div>
                    </div>
                </div>
                <div style="clear:both;"></div>
            </div>
        </div>
        <homeFooter />
    </div>
</template>

<script>
import homeHeader from '@/components/homeHeader.vue'
import homeFooter from '@/components/homeFooter.vue'
import { queryStudentClassesSubjectList } from "@/api/home";
export default {
    components: {
        homeHeader, homeFooter
    },
    data() {
        return {
            lessonList: []
        }
    },
    mounted() {
        this.queryStudentClassesSubjectList()
    },
    beforeDestroy() {
    },
    methods: {
        toDetail(id) {
            this.$router.push({
                path: '/lessonDetail',
                query: { id }
            })
        },
        async queryStudentClassesSubjectList() {
            let res = await queryStudentClassesSubjectList({})
            if (res.code == 200) {
                res.data.forEach((d) => {
                    if (d.finishDate) {
                        let date = new Date(d.finishDate);
                        let year = date.getFullYear();
                        let month = date.getMonth() + 1;
                        month = month >= 10 ? month : '0' + month;
                        let day = date.getDate();
                        day = day >= 10 ? day : '0' + day;
                        d.finish = `${year}年${month}月${day}日已完成`
                    }
                })
                this.lessonList = res.data;
            }
        }
    }
}
</script>
<style scoped lang="less">
header {
    width: 100%;
    height: .92rem;
}

.home {
    background: #f5f6f8;
    width: 100%;
}

.content {
    width: 14.78rem;
    margin: auto;
    padding-bottom: .5rem;

    .title {
        display: flex;
        align-items: center;
        margin: .39rem 0;

        img {
            width: .4rem;
            height: .4rem;
            margin-right: .07rem;
        }

        span {

            font-size: .3rem;
            font-family: 'pangmen';
            font-weight: 400;
            color: #2D2D2D;
        }
    }

    .list {

        .list_item {
            width: 3.47rem;
            height: 3.9rem;
            background: #FFFFFF;
            box-shadow: 0 0 .2rem 0 rgba(0, 0, 0, 0.3);
            border-radius: .1rem;
            display: inline-block;
            float: left;
            margin-right: .3rem;
            margin-bottom: .3rem;
            overflow: hidden;
            position: relative;
            cursor: pointer;
            transition: transform .3s;

            &:hover {
                transform: scale(1.03);
            }

            &:nth-child(4n) {
                margin-right: 0;
            }

            .photo {
                width: 100%;
                height: 1.96rem;
                background: #eee;
            }

            >div {
                padding: .19rem .2rem;
                box-sizing: border-box;
                width: 100%;

                .name {
                    font-size: .2rem;
                    font-family: 'PingFang SC';
                    font-weight: 700;
                    color: #36393D;
                }

                .teacher {
                    font-size: .16rem;
                    font-family: 'PingFang SC';
                    font-weight: 400;
                    color: #6D737A;
                    margin: .19rem 0 .2rem;
                }

                .text {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .left {
                        font-size: .16rem;
                        font-family: 'PingFang SC';
                        font-weight: 400;
                        color: #4C88EB;
                    }

                    .right {
                        font-size: .16rem;
                        font-family: OPPOSans;
                        font-weight: 800;
                        color: #FF7800;
                    }
                }

                .bottom {
                    position: absolute;
                    left: .2rem;
                    bottom: .2rem;
                    display: flex;
                    align-items: center;

                    img {
                        width: .162rem;
                        height: .162rem;
                        margin-right: .07rem;
                    }

                    font-size: .16rem;
                    font-family: 'PingFang SC';
                    font-weight: 400;
                    color: #979EA7;
                }
            }
        }
    }
}
</style>

