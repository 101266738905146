import request from '../request'

//登录
export function login(data) {
  return request({
    url: '/prod-api/api/common/studentLogin',
    method: 'post',
    data: data
  })
}
export function register(data) {
  return request({
    url: '/prod-api/api/student/firstApplyJoinClasses',
    method: 'post',
    data: data
  })
}
export function queryCanJoinClasses(data) {
  return request({
    url: '/prod-api/api/student/queryCanJoinClasses',
    method: 'post',
    data: data
  })
}
export function sendEmailCode(params) {
  return request({
    url: '/prod-api/api/studentCenter/sendEmailCode',
    method: 'get',
    params: params
  })
}

export function resetLoginPwd(data) {
  return request({
    url: '/prod-api/api/studentCenter/resetLoginPwd',
    method: 'post',
    data: data
  })
}
