<template>
    <div class="container">
        <div class="list_item">
            <div class="name fs22">个人信息</div>
            <div class="btn1" @click="setUserInfo">设置个人信息</div>
        </div>
        <div class="list_item">
            <div class="name fs22">账号安全</div>
        </div>
        <div class="list_item">
            <div class="name">修改密码</div>
            <div class="input">
                <el-input type="text" placeholder="请输入原密码" v-model="form.oldLoginPwd" show-password />
            </div>
        </div>
        <div class="list_item">
            <div class="name"></div>
            <div class="input">
                <el-input type="text" placeholder="请输入新密码" v-model="form.newLoginPwd" show-password />
            </div>
            <div class="input" style="margin-left: .2rem;">
                <el-input type="text" placeholder="请确认新密码" v-model="form.rePwd" show-password />
            </div>
        </div>
        <div class="list_item">
            <div class="name"></div>
            <div class="msg"><span>*</span>至少填写8位字符，必须包含数字和英文字母的组合</div>
        </div>
        <div class="list_item">
            <div class="name"></div>
            <div class="btn2" @click="changePwd()">确定</div>
        </div>
        <div class="list_item">
            <div class="name">修改邮箱</div>
            <div class="input">
                <el-input type="text" placeholder="请输入新邮箱" v-model="email" />
            </div>
            <div class="btn2" style="margin-left: .5rem;" @click="changeEmail()">确定</div>
        </div>
        <div class="list_item">
            <div class="name">修改手机号</div>
            <div class="input">
                <el-input type="text" placeholder="请输入新手机号" v-model="phone" />
            </div>
            <div class="btn2" style="margin-left: .5rem;" @click="changePhone()">确定</div>
        </div>
        <div class="list_item">
            <div class="name">注销账号</div>
            <div class="btn3" @click="qxModal = true">注销</div>
        </div>
        <div class="list_item">
            <div class="name fs22">关于我们</div>
            <!-- <div class="btn1" @click="saveStudentIntroduce()">关于我们</div> -->
            <span>联系电话：0755-24721999</span>
        </div>
        <el-dialog title="" :visible.sync="qxModal" width="25%">
            <h2 style="width: 100%;text-align: center;">是否退出账号?</h2>
            <div class="btn2">
                <div class="qx" @click="qxModal = false">取消</div>
                <el-button class="qd" type="primary" @click="leave()">
                    确定
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { changePwd, changeEmail, changePhone, getLoginStudentInfo, loginOut } from "@/api/mine";
export default {
    data() {
        return {
            form: {
                oldLoginPwd: '',
                newLoginPwd: '',
                rePwd: ''
            },
            email: '',
            phone: '',
            qxModal: false
        }
    },
    mounted() {
        this.getLoginStudentInfo()
    },
    methods: {
        async getLoginStudentInfo() {
            let res = await getLoginStudentInfo({});
            this.email = res.data.email || '';
            this.phone = res.data.phone || '';
        },
        setUserInfo() {
            this.$parent.open()
        },
        async changePwd() {
            if (this.form.oldLoginPwd.length == 0) {
                this.$message.error('请输入原密码');
            } else if (this.form.newLoginPwd.length == 0) {
                this.$message.error('请输入新密码');
            } else if (this.form.newLoginPwd != this.form.rePwd) {
                this.$message.error('两次密码输入不相同');
            } else {
                let reg =
                    /^(?![0-9]+$)(?![a-zA-Z]+$)(?!(1|[\(\)])+$)([^(0-9a-zA-Z)]|[\\(\\)]|[a-zA-Z]|[0-9]){8,20}$/
                if (!reg.test(this.form.newLoginPwd)) {
                    this.$message.error('密码格式不正确,请重新输入');
                } else {
                    let res = await changePwd({
                        oldLoginPwd: this.form.oldLoginPwd,
                        newLoginPwd: this.form.newLoginPwd
                    })
                    if (res.code == 200) {
                        this.$message.success('修改成功');
                        setTimeout(() => {
                            this.leave()
                        }, 600)
                    }
                }
            }
        },
        async changeEmail() {
            var patten = /^([a-zA-Z0-9]+[_|_|\-|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
            if (!patten.test(this.email)) {
                this.$message.error('邮箱格式不正确');
            } else {
                let res = await changeEmail({
                    email: this.email
                })
                if (res.code == 200) {
                    this.$message.success('修改成功');
                }
            }
        },
        async changePhone() {
            var num_reg = /^13[0-9]{1}[0-9]{8}$|15[0-9]{1}[0-9]{8}$|16[0-9]{1}[0-9]{8}$|18[0-9]{1}[0-9]{8}|17[0-9]{1}[0-9]{8}$|19[0-9]{1}[0-9]{8}$|14[0-9]{1}[0-9]{8}/;
            if (num_reg.test(this.phone)) {
                let res = await changePhone({
                    phone: this.phone
                })
                if (res.code == 200) {
                    this.$message.success('修改成功');
                    setTimeout(() => {
                        this.leave()
                    }, 600)
                }
            } else {
                this.$message.error('手机号格式不正确');
            }
        },
        async leave() {
            let res = await loginOut({})
            if (res.code == 200) {
                this.$message.success('注销成功');
                setTimeout(() => {
                    this.$store.dispatch('CLEAR')
                    localStorage.removeItem('eduUserInfo')
                    this.$router.push('/login')
                }, 600)
            }
        }
    }
}
</script>
<style scoped lang="less">
::v-deep .el-dialog {
    padding: .4rem 0;
    border-radius: .16rem;
}

.container {
    width: 100%;
    padding-top: .3rem;
    padding-bottom: .5rem;

    .btn2 {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: .3rem;

        .qx {
            width: 1.43rem;
            height: .48rem;
            border: .01rem solid #DDDDDD;
            border-radius: .24rem;
            font-size: .18rem;
            font-family: 'PingFang SC';
            font-weight: 400;
            color: #36393D;
            text-align: center;
            line-height: .48rem;
            margin: 0 .15rem;
            cursor: pointer;
            transition: filter 0.3s;

            &:hover {
                filter: brightness(1.01);
            }
        }

        .qd {
            width: 1.43rem;
            height: .48rem;
            background: linear-gradient(90deg, #4C88EB, #3D70E7);
            border-radius: .24rem;
            font-size: .18rem;
            font-family: 'PingFang SC';
            font-weight: 400;
            color: #FFFFFF;
            text-align: center;
            margin: 0 .15rem;
            cursor: pointer;
            transition: filter 0.3s;

            &:hover {
                filter: brightness(1.1);
            }
        }
    }

    .list_item {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: .3rem;
        align-items: center;

        .msg {

            font-size: .16rem;
            color: #6D737A;

            span {
                color: #F20000;
            }
        }

        .name {
            font-size: .18rem;
            font-family: 'PingFang SC';
            font-weight: 400;
            color: #36393D;
            width: 1.2rem;
            flex-shrink: 0;
        }

        .fs22 {
            font-size: .22rem;
        }

        .input {
            width: 3.22rem;
            border-radius: .06rem;

            ::v-deep .el-input__inner {
                width: 3.22rem;
                height: .5rem;
                background: #F7F8F9;
                border-radius: .06rem;
                border: 0;
                outline: 0;
                padding-left: .2rem;
                padding-right: .2rem;
                font-size: .18rem;

                &::placeholder {
                    color: #BEC2C7;
                }
            }
        }

        .btn1 {
            width: 1.5rem;
            height: .5rem;
            background: rgba(76, 136, 235, 0.1);
            border-radius: .06rem;
            font-size: .18rem;
            font-family: 'PingFang SC';
            font-weight: 400;
            color: #4C88EB;
            line-height: .5rem;
            text-align: center;
            cursor: pointer;
            transition: filter 0.3s;

            &:hover {
                filter: brightness(1.1);
            }
        }

        .btn2 {
            cursor: pointer;
            width: 1.43rem;
            height: .48rem;
            background: linear-gradient(90deg, #4C88EB, #3D70E7);
            border-radius: .36rem;
            font-size: .18rem;
            text-align: center;
            color: #FFFFFF;
            line-height: .48rem;
            transition: filter 0.3s;

            &:hover {
                filter: brightness(1.1);
            }
        }

        .btn3 {
            cursor: pointer;
            width: 1.43rem;
            height: .48rem;
            background: #EDF2F6;
            border-radius: .36rem;
            font-size: .18rem;
            font-family: 'PingFang SC';
            font-weight: 400;
            color: #36393D;
            text-align: center;
            line-height: .48rem;
            transition: filter 0.3s;

            &:hover {
                filter: brightness(1.03);
            }
        }
    }
}
</style>
