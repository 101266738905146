<template>
    <div class="container">
        <div class="list_item">
            <div class="name">爱好情况</div>
            <div class="input">
                <el-input type="text" placeholder="请输入爱好情况" v-model="hoby" />
            </div>
            <div class="btn" @click="saveStudentIntroduce(1)">修改</div>
        </div>
        <div class="list_item">
            <div class="name">业务情况</div>
            <div class="input">
                <el-input type="text" placeholder="请输入业务情况" v-model="work" />
            </div>
            <div class="btn" @click="saveStudentIntroduce(2)">修改</div>
        </div>
        <div class="list_item">
            <div class="name">学习情况</div>
            <div class="input">
                <el-input type="text" placeholder="请输入学习情况" v-model="learn" />
            </div>
            <div class="btn" @click="saveStudentIntroduce(3)">修改</div>
        </div>
        <div class="list_item">
            <div class="name">学习目标</div>
            <div class="input">
                <el-input type="text" placeholder="请输入学习目标" v-model="purpose" />
            </div>
            <div class="btn" @click="saveStudentIntroduce(4)">修改</div>
        </div>
        <div class="list_item">
            <div class="name">教育背景</div>
            <div class="input">
                <el-input type="text" placeholder="请输入教育背景" v-model="school" />
            </div>
            <div class="btn" @click="saveStudentIntroduce(5)">修改</div>
        </div>
    </div>
</template>

<script>
import { queryStudentIntroduce, saveStudentIntroduce } from "@/api/mine";
export default {
    data() {
        return {
            hoby: '',
            work: '',
            learn: '',
            purpose: '',
            school: '',
        }
    },
    mounted() {
        this.queryStudentIntroduce(1)
        this.queryStudentIntroduce(2)
        this.queryStudentIntroduce(3)
        this.queryStudentIntroduce(4)
        this.queryStudentIntroduce(5)
    },
    methods: {
        async queryStudentIntroduce(e) {
            const res = await queryStudentIntroduce({ type: e });
            if (res.code == 200) {
                switch (e) {
                    case 1:
                        this.hoby = res.data ? res.data : '';
                        break;
                    case 2:
                        this.work = res.data ? res.data : '';
                        break;
                    case 3:
                        this.learn = res.data ? res.data : '';
                        break;
                    case 4:
                        this.purpose = res.data ? res.data : '';
                        break;
                    case 5:
                        this.school = res.data ? res.data : '';
                        break;
                }
            }
        },
        async saveStudentIntroduce(e) {
            let res = {};
            switch (e) {
                case 1:
                    res = await saveStudentIntroduce({
                        type: e,
                        content: this.hoby
                    });
                    break;
                case 2:
                    res = await saveStudentIntroduce({
                        type: e,
                        content: this.work
                    });
                    break;
                case 3:
                    res = await saveStudentIntroduce({
                        type: e,
                        content: this.learn
                    });
                    break;
                case 4:
                    res = await saveStudentIntroduce({
                        type: e,
                        content: this.purpose
                    });
                    break;
                case 5:
                    res = await saveStudentIntroduce({
                        type: e,
                        content: this.school
                    });
                    break;
            }
            if (res.code == 200) {
                this.$message.success('修改成功');
            }
        }

    },
}
</script>
<style scoped lang="less">
.container {
    width: 100%;
    padding-top: .3rem;
    padding-bottom: .5rem;

    .list_item {
        display: flex;
        align-items: center;
        margin-bottom: .3rem;

        .name {
            font-size: .18rem;
            font-family: 'PingFang SC';
            font-weight: 400;
            color: #36393D;
        }

        .input {
            width: 5.2rem;
            background: #F7F8F9;
            border-radius: .06rem;
            margin: 0 .4rem;

            ::v-deep .el-input__inner {
                width: 5.2rem;
                height: .5rem;
                background: #F7F8F9;
                border-radius: .06rem;
                border: 0;
                outline: 0;
                padding-left: .2rem;
                padding-right: .2rem;
                font-size: .18rem;

                &::placeholder {
                    color: #BEC2C7;
                }
            }
        }

        .btn {
            font-size: .18rem;
            font-family: 'PingFang SC';
            font-weight: 400;
            color: #4C88EB;
            cursor: pointer;
        }
    }
}
</style>
