import request from '../request'

export function selectHomeInfo(params) {
  return request({
    url: '/llmApi/home/selectHomeInfo',
    method: 'get',
    params: params
  })
}
export function selectDataSetListByHome(params) {
  return request({
    url: '/llmApi/home/selectDataSetListByHome',
    method: 'get',
    params: params
  })
}
export function getRankingListModelInfo(params) {
  return request({
    url: '/llmApi/home/getRankingListModelInfo',
    method: 'get',
    params: params
  })
}
export function queryStudentClassesSubjectList(params) {
  return request({
    url: '/prod-api/api/student/queryStudentClassesSubjectList',
    method: 'get',
    params: params
  })
}
export function queryWaitFinishTask(params) {
  return request({
    url: '/prod-api/api/studentHome/queryWaitFinishTask',
    method: 'get',
    params: params
  })
}
export function queryRecommSubject(params) {
  return request({
    url: '/prod-api/api/studentHome/queryRecommSubject',
    method: 'get',
    params: params
  })
}
export function querySubjectInfo(params) {
  return request({
    url: '/prod-api/api/student/querySubjectInfo',
    method: 'get',
    params: params
  })
}
export function applyUnitTesting(params) {
  return request({
    url: '/prod-api/api/student/applyUnitTesting',
    method: 'get',
    params: params
  })
}
export function collectSubjectOpt(data) {
  return request({
    url: '/prod-api/api/student/collectSubjectOpt',
    method: 'post',
    data: data
  })
}
export function queryCollectSubjectList(params) {
  return request({
    url: '/prod-api/api/student/queryCollectSubjectList',
    method: 'get',
    params: params
  })
}
export function createFolder(data) {
  return request({
    url: '/prod-api/api/student/createFolder',
    method: 'post',
    data: data
  })
}
export function deleteFolder(params) {
  return request({
    url: '/prod-api/api/student/deleteFolder',
    method: 'get',
    params: params
  })
}
export function queryStudentTaskInfo(params) {
  return request({
    url: '/prod-api/api/studentHome/queryStudentTaskInfo',
    method: 'get',
    params: params
  })
}
export function dailyTaskCorrect(params) {
  return request({
    url: '/prod-api/api/studentHome/dailyTaskCorrect',
    method: 'get',
    params: params
  })
}

export function submitDailyTask(data) {
  return request({
    url: '/prod-api/api/studentHome/submitDailyTask',
    method: 'post',
    data: data
  })
}
export function restartPractice(params) {
  return request({
    url: '/prod-api/api/studentHome/restartPractice',
    method: 'get',
    params: params
  })
}
export function startTesting(params) {
  return request({
    url: '/prod-api/api/student/startTesting',
    method: 'get',
    params: params
  })
}
export function startExam(params) {
  return request({
    url: '/prod-api/api/student/startExam',
    method: 'get',
    params: params
  })
}

export function answerTesting(data) {
  return request({
    url: '/prod-api/api/student/answerTesting',
    method: 'post',
    data: data
  })
}
export function answerExam(data) {
  return request({
    url: '/prod-api/api/student/answerExam',
    method: 'post',
    data: data
  })
}
export function submitCustomTask(data) {
  return request({
    url: '/prod-api/api/studentHome/submitCustomTask',
    method: 'post',
    data: data
  })
}
export function joinStudySubject(params) {
  return request({
    url: '/prod-api/api/student/joinStudySubject',
    method: 'get',
    params: params
  })
}
export function queryCategoryList(params) {
  return request({
    url: '/prod-api/api/common/queryCategoryList',
    method: 'get',
    params: params
  })
}
export function queryElectiveSubjectList(params) {
  return request({
    url: '/prod-api/api/studentHome/queryElectiveSubjectList',
    method: 'get',
    params: params
  })
}
