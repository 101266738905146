<template>
    <div style="width: 100%;" class="workDetail">
        <header>
            <homeHeader :active="2" />
        </header>
        <div class="content">
            <div class="top">
                <div class="back" @click="back()">
                    <img src="../static/img/icon_back2.png">返回
                </div>
                <div class="djs">
                    <p>倒计时</p>
                    <span>{{ parseInt(count / 60 / 60 % 60) < 10 ? "0" + parseInt(count / 60 / 60 % 60) : parseInt(count /
                        60 / 60 % 60) }}</span>
                            <i>:</i>
                            <span>{{ parseInt(count / 60 % 60) < 10 ? "0" + parseInt(count / 60 % 60) : parseInt(count / 60
                                % 60) }}</span>
                                    <i>:</i>
                                    <span>{{ parseInt(count % 60) < 10 ? "0" + parseInt(count % 60) : parseInt(count % 60)
                                    }} </span>
                </div>
            </div>
            <div class="section">
                <div class="swiper-item">
                    <div class="title">
                        <span>{{ currentNum }}.</span>
                        <div class="icon">
                            <span v-if="questionList[currentNum - 1].type == 1">单选</span>
                            <span v-if="questionList[currentNum - 1].type == 2">多选</span>
                            <span v-if="questionList[currentNum - 1].type == 3">判断</span>
                            <span v-if="questionList[currentNum - 1].type == 4">填空</span>
                            <span v-if="questionList[currentNum - 1].type == 5">问答</span>
                        </div>
                        <div v-html="questionList[currentNum - 1].content"></div>
                    </div>
                    <div class="questionItemlList">
                        <div v-if="questionList[currentNum - 1].type == 1 || questionList[currentNum - 1].type == 3">
                            <div class="danxuan" v-for="item2 in questionList[currentNum - 1].questionItemlList"
                                :key="item2.id" @click="changeDanXuan(questionList[currentNum - 1].id, item2.id)">
                                <div :class="item2.checked ? 'activeItemTag' : 'itemTag'">
                                </div>
                                {{ item2.itemTag }}.
                                <div style="margin-left: .05rem;" v-html="item2.itemContent"></div>
                            </div>
                        </div>
                        <div v-if="questionList[currentNum - 1].type == 2">
                            <div class="danxuan" v-for="item2 in questionList[currentNum - 1].questionItemlList"
                                :key="item2.id" @click="changeDuoXuan(questionList[currentNum - 1].id, item2.id)">
                                <div :class="item2.checked ? 'activeItemTag' : 'itemTag'">
                                </div>
                                {{ item2.itemTag }}.
                                <div style="margin-left: .05rem;" v-html="item2.itemContent"></div>
                            </div>
                        </div>
                        <div v-if="questionList[currentNum - 1].type == 4">
                            <div class="wenda" v-for="(item2, i) in JSON.parse(questionList[currentNum - 1].answer)"
                                :key="i">
                                <div class="inpit_" @click="ids = i; QID = questionList[currentNum - 1].id">
                                    <quill-editor v-model="tkAnswer[i]" ref="editorRef" :options="editorOption">
                                    </quill-editor>
                                </div>
                            </div>
                        </div>
                        <div v-if="questionList[currentNum - 1].type == 5">
                            <div class="wenda" @click="ids = -1; QID = questionList[currentNum - 1].id">
                                <quill-editor v-model="tkAnswer[0]" ref="editorRef" :options="editorOption">
                                </quill-editor>
                            </div>
                        </div>
                        <el-upload class="avatar-uploader1" :file-list="fileList"
                            action="https://eeapi.appcloud.tech/prod-api/api/common/upload" :limit="1"
                            :shrow-file-list='false' :on-success="uploadSuccess" />
                    </div>
                </div>

            </div>
            <div class="bottom">
                <div v-if="currentNum == 1 && questionNum != 1">
                    <div class="next1" @click="next()">下一题</div>
                </div>
                <div class="btn_box" v-if="currentNum != 1 && currentNum != questionNum && questionNum != 1">
                    <div class="exam_btn" @click="pre()">上一题</div>
                    <div class="exam_btn" @click="next()">下一题</div>
                </div>
                <div class="btn_box" v-if="currentNum == questionNum && questionNum != 1">
                    <div class="exam_btn" @click="pre()">上一题</div>
                    <div class="exam_btn" @click="showConfirmModal = true">提交</div>
                </div>
                <div class="btn_box" v-if="questionNum == 1">
                    <div class="next1" @click="showConfirmModal = true">提交</div>
                </div>
            </div>
        </div>
        <el-dialog title="" :visible.sync="showConfirmModal" width="30%">
            <h2 style="width: 100%;text-align: center;">提交后本次测验答案将不可修改，确定要提交?</h2>
            <div class="btn2">
                <div class="qx" @click="showConfirmModal = false">取消</div>
                <el-button class="qd" type="primary" @click="confirm()">
                    确定
                </el-button>
            </div>
        </el-dialog>
        <homeFooter style="margin-top: .8rem;" />
    </div>
</template>

<script>
import homeHeader from '@/components/homeHeader.vue'
import homeFooter from '@/components/homeFooter.vue'
import { startTesting, startExam, answerTesting, answerExam } from "@/api/home";
import { quillEditor } from "vue-quill-editor"; //调用编辑器
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import * as Quill from 'quill'
import imageResize from 'quill-image-resize-module'
import { Container } from 'element-ui';
Quill.register('modules/imageResize', imageResize)

export default {
    components: {
        homeHeader, homeFooter, quillEditor
    },
    data() {
        return {
            content: '',
            editorOption: {
                placeholder: '请在这里输入', // 默认提示 被上面覆盖了
                theme: 'snow', //主题 snow：有工具栏的；bubble：只有文本域的
                modules: {
                    imageResize: { // 放大缩小
                    },
                    toolbar: {
                        container: [['image']],
                        handlers: {
                            'image': function (value) {
                                if (value) {
                                    // 触发input框选择图片文件
                                    document.querySelector('.avatar-uploader1 input').click()
                                } else {
                                    this.quill.format('image', false);
                                }
                            }
                        }
                    }
                }
            },
            submit: false,
            loading: false,
            ids: '',
            id: '',
            questionId: '',
            time: '',
            count: '',
            showModal: false,
            showConfirmModal: false,
            questionNum: '',
            currentNum: 1,
            questionList: [],
            QID: '',
            itemTag: [],
            answer: {},
            tiankongs: {},
            interval: null,
            allCount: '',
            fultureTime: '',
            type: '',
            tkAnswer: [],
            fileList: []
        }
    },
    mounted() {
        this.id = this.$route.query.id
        this.type = this.$route.query.type
        this.startTesting(this.id);
        this.allCount = this.$route.query.duration * 60000;
        this.getServerTime();
    },
    watch: {
        count(newValue, oldValue) {
            if (this.count <= 0) {
                this.count = 0;
                clearInterval(this.interval)
                let fultureTime = localStorage.getItem('fultureTime');
                fultureTime = JSON.parse(fultureTime).splice(JSON.parse(fultureTime).findIndex(item => item.id == this.id), 1)
                localStorage.setItem('fultureTime', JSON.stringify(fultureTime))
            }
        }
    },
    beforeDestroy() {
    },
    methods: {
        back() {
            window.history.go(-1);
        },
        uploadSuccess(res, file) {
            this.fileList = [];
            // res为图片服务器返回的数据
            // 获取富文本组件实例
            var quill;
            if (this.ids != -1) {
                quill = this.$refs.editorRef[this.ids].quill;
            } else {
                if (this.$refs.editorRef[0]) {
                    quill = this.$refs.editorRef[0].quill;
                } else {
                    quill = this.$refs.editorRef.quill;
                }
            }
            // 如果上传成功
            if (res.code === 200) {
                // 获取光标所在位置
                let length = quill.selection.savedRange.index;
                // 插入图片  res.info为服务器返回的图片地址
                quill.insertEmbed(length, 'image', 'https://eeapi.appcloud.tech' + res.fileName)
                // 调整光标到最后
                quill.setSelection(length + 1)
                this.$forceUpdate()
            } else {
                this.$message.error('图片插入失败')
            }
        },
        getServerTime() {
            let fultureTime = localStorage.getItem('fultureTime');
            let check = false;
            if (JSON.parse(fultureTime)) {
                JSON.parse(fultureTime).forEach((d) => {
                    if (d.id == this.id) {
                        check = true;
                        this.fultureTime = d.fultureTime
                    }
                })
            }
            if (check) {
                var date = new Date();
                this.time = date.getTime();
                if (this.fultureTime - this.time > 0) {
                    this.count = (this.fultureTime - this.time) / 1000
                    this.interval = setInterval(() => {
                        this.count--
                    }, 1000)
                } else {
                    this.count = 0
                }
            } else {
                var date = new Date();
                this.time = date.getTime();
                let fTime = this.time + this.allCount
                this.count = (fTime - this.time) / 1000
                this.interval = setInterval(() => {
                    this.count--
                }, 1000)
                let fultureTimeArr = {
                    id: this.id,
                    fultureTime: fTime
                };
                let futureInfo = localStorage.getItem('fultureTime');
                if (futureInfo) {
                    futureInfo = JSON.parse(futureInfo)
                    if (futureInfo.length > 0) {
                        futureInfo.push(fultureTimeArr);
                        futureInfo = JSON.stringify(futureInfo)
                        localStorage.setItem('fultureTime', futureInfo)

                    }
                } else {
                    let newarr = [];
                    newarr.push(fultureTimeArr);
                    newarr = JSON.stringify(newarr)
                    localStorage.setItem('fultureTime', newarr)
                }
            }
        },
        changeDanXuan(id1, id2) {
            this.questionList.forEach((d) => {
                if (d.id == id1) {
                    d.questionItemlList.forEach((e) => {
                        if (e.id != id2) {
                            e.checked = false
                        } else {
                            e.checked = true;
                            this.itemTag = [e.itemTag]
                        }
                    })
                }
            })
            this.QID = id1;
            this.$forceUpdate()
        },
        changeDuoXuan(id1, id2) {
            this.questionList.forEach((d) => {
                if (d.id == id1) {
                    d.questionItemlList.forEach((e) => {
                        if (e.id == id2) {
                            e.checked = !e.checked;
                            if (e.checked) {
                                this.itemTag.push(e.itemTag)
                            } else {
                                this.itemTag.splice(this.itemTag.findIndex(item => item == e.itemTag),
                                    1)
                            }
                        }
                    })
                }
            })
            this.QID = id1;
            this.$forceUpdate()
        },
        async startTesting(id) {
            if (this.type == 'test') {
                var res = await startTesting({
                    id
                })
            } else {
                var res = await startExam({
                    id
                })
            }

            if (res.code == 200) {
                let questionList = [];
                res.data.partList.forEach((d) => {
                    d.questionList.forEach((e) => {
                        questionList.push(e)
                    })
                })
                this.questionList = questionList;
                // console.log(this.questionList);
                this.questionNum = questionList.length;
                this.questionId = res.data.id;

                let num = this.currentNum;
                if (JSON.parse(this.questionList[num - 1].studentAnswer)) {
                    if (this.questionList[num - 1].type == 1 || this.questionList[num - 1].type == 3) {
                        if (JSON.parse(this.questionList[num - 1].studentAnswer)) {
                            let studentAnswer = JSON.parse(this.questionList[num - 1].studentAnswer);
                            this.questionList[num - 1].questionItemlList.forEach((a) => {
                                if (a.itemTag == studentAnswer[0]) {
                                    a.checked = true;
                                    // console.log('回显');
                                }
                            })
                        }
                    } else if (this.questionList[num - 1].type == 2) {
                        if (JSON.parse(this.questionList[num - 1].studentAnswer)) {
                            let studentAnswer = JSON.parse(this.questionList[num - 1].studentAnswer);
                            studentAnswer.forEach((e) => {
                                this.questionList[num - 1].questionItemlList.forEach((a) => {
                                    if (a.itemTag == e) {
                                        a.checked = true;
                                    }
                                })
                            })
                        }
                    } else if (this.questionList[num - 1].type == 4) {
                        let studentAnswer = JSON.parse(this.questionList[num - 1].studentAnswer);
                        studentAnswer.forEach((d, index) => {
                            this.tkAnswer[index] = d
                        })
                    } else if (this.questionList[num - 1].type == 5) {
                        let studentAnswer = JSON.parse(this.questionList[num - 1].studentAnswer);
                        // this.tkAnswer[0] = studentAnswer
                    }
                }
            }
        },
        async answerTesting(answer, questionId) {
            if (this.type == 'test') {
                let res = await answerTesting({
                    answer: answer,
                    id: this.questionId,
                    questionId: questionId,
                    submit: this.submit
                })
                if (res.code == 200 && !this.submit) {
                    this.startTesting(this.id);
                    this.loading = false;
                }
            } else {
                let res = await answerExam({
                    answer: answer,
                    id: this.questionId,
                    questionId: questionId,
                    submit: this.submit
                })
                if (res.code == 200 && !this.submit) {
                    this.startTesting(this.id);
                    this.loading = false;
                }
            }
            if (this.currentNum != this.questionNum) {
                this.loading = false;
                this.currentNum++;
                this.QID = '';
                this.itemTag = [];
                this.tkAnswer = []
            } else {
                this.$message.success('提交成功，请等待老师批改')
                setTimeout(() => {
                    this.$router.replace({
                        path: '/courseList'
                    })
                }, 1000);
            }
        },
        next() {
            let num = this.currentNum;
            let type = this.questionList[num - 1].type;
            let check = false;
            if (type == 4) {
                let nums = 0;
                this.itemTag = this.tkAnswer;
                this.itemTag.forEach((d) => {
                    nums++
                })
                if (nums != JSON.parse(this.questionList[this.currentNum - 1].answer).length) {
                    check = true
                }
                this.QID = this.questionList[num - 1].id;
            } else if (type == 5) {
                this.itemTag = this.tkAnswer;
                this.QID = this.questionList[num - 1].id;
            }
            if (check) {
                this.$message.error('请先作答完当前题')
            } else if (this.itemTag.length > 0) {
                this.loading = true;
                this.answerTesting([...this.itemTag], this.QID);
            } else if (this.questionList[num - 1].status == 1) {
                if (this.currentNum != this.questionNum) {
                    this.currentNum++;
                } else {
                    this.$message.success('提交成功，请等待老师批改')
                    setTimeout(() => {
                        this.$router.replace({
                            path: '/courseList'
                        })
                    }, 1000);
                }
                this.startTesting(this.id);
            } else {
                this.$message.error('请先作答完当前题')
            }
        },
        pre() {
            this.currentNum--;
            this.itemTag = [];
            this.startTesting(this.id);
        },
        confirm() {
            this.showConfirmModal = false;
            this.submit = true;
            this.next()
        }
    }
}
</script>
<style scoped lang="less">
header {
    width: 100%;
    height: .92rem;
}

.workDetail {
    background: #f5f6f8;
    width: 100%;
    padding-top: .3rem;
    box-sizing: border-box;
    overflow: hidden;
}

.btn2 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: .3rem;

    .qx {
        width: 1.43rem;
        height: .48rem;
        border: .01rem solid #DDDDDD;
        border-radius: .24rem;
        font-size: .18rem;
        font-family: 'PingFang SC';
        font-weight: 400;
        color: #36393D;
        text-align: center;
        line-height: .48rem;
        margin: 0 .15rem;
        cursor: pointer;
        transition: filter 0.3s;

        &:hover {
            filter: brightness(1.01);
        }
    }

    .qd {
        width: 1.43rem;
        height: .48rem;
        background: linear-gradient(90deg, #4C88EB, #3D70E7);
        border-radius: .24rem;
        font-size: .18rem;
        font-family: 'PingFang SC';
        font-weight: 400;
        color: #FFFFFF;
        text-align: center;
        margin: 0 .15rem;
        cursor: pointer;
        transition: filter 0.3s;

        &:hover {
            filter: brightness(1.1);
        }
    }
}

.content {
    width: 14.8rem;
    min-height: 6.68rem;
    margin: auto;
    padding-bottom: .5rem;
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: 0px 0px .2rem 0px rgba(0, 0, 0, 0.16);
    border-radius: .16rem;
    overflow: hidden;
    position: relative;

    .top {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .djs {
            display: flex;
            align-items: center;
            margin-right: .5rem;
            margin-top: .21rem;

            p {
                height: .3rem;
                font-size: .18rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #979EA7;
                opacity: 0.8;
                margin-right: .09rem;
            }

            i {
                font-style: normal;
                width: .22rem;
                height: .3rem;
                line-height: .3rem;
                text-align: center;
            }

            span {
                padding: 0 .05rem;
                height: .3rem;
                background: rgba(76, 136, 235, 0.1);
                border-radius: .04rem;
                text-align: center;
                line-height: .3rem;
                font-size: .24rem;
                font-family: Alimama FangYuanTi VF;
                font-weight: bold;
                color: #4C88EB;
            }
        }
    }

    .back {
        font-size: .18rem;
        font-family: PingFang SC;
        font-weight: 400;
        color: #979EA7;
        display: flex;
        align-items: center;
        margin: .21rem 0 0 .48rem;
        cursor: pointer;

        img {
            width: .2rem;
            height: .2rem;
            margin-right: .08rem;
        }
    }

    .section {
        padding: .41rem .6rem;

        .swiper-item {
            width: 100%;

            .title {
                display: flex;
                align-items: center;
                font-size: .2rem;
                font-family: PingFang SC;
                font-weight: 700;
                color: #36393D;

                .icon {
                    width: .58rem;
                    height: .32rem;
                    line-height: .32rem;
                    border: 1px solid #4C88EB;
                    border-radius: .1rem;
                    font-size: .16rem;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #4C88EB;
                    text-align: center;
                    margin: 0 .1rem;
                }
            }

            .questionItemlList {
                margin-top: .3rem;

                .danxuan {
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    margin-top: .1rem;
                    font-size: .2rem;
                    font-family: PingFang SC;
                    font-weight: 500;
                    color: #36393D;
                }

                .wenda {
                    width: 100%;

                    .inpit_ {
                        margin-top: .2rem;
                    }
                }

                .itemTag {
                    cursor: pointer;
                    width: .25rem;
                    height: .25rem;
                    background: #FFFFFF;
                    border: .01rem solid #DDDDDD;
                    border-radius: 50%;
                    margin: .1rem .2rem .1rem 0;
                }

                .activeItemTag {
                    cursor: pointer;
                    width: .25rem;
                    height: .25rem;
                    background: #FFFFFF;
                    border: .01rem solid transparent;
                    border-radius: 50%;
                    margin: .1rem .2rem .1rem 0;
                    background-image: url(../static/img/xz2.png);
                    background-size: 100% 100%;
                }
            }
        }

    }

    .bottom {
        height: 157px;
        width: 100%;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        // position: absolute;
        // bottom: .5rem;
        // left: 0;

        .next1 {
            font-size: .2rem;
            font-family: PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            width: 3.7rem;
            height: .72rem;
            background: linear-gradient(90deg, #4C88EB, #3D70E7);
            border-radius: .36rem;
            text-align: center;
            line-height: .72rem;
            cursor: pointer;
            transition: filter 0.3s;

            &:hover {
                filter: brightness(1.1);
            }
        }

        .btn_box {
            display: flex;

            .exam_btn {
                font-size: .2rem;
                font-family: PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                width: 3.7rem;
                height: .72rem;
                background: linear-gradient(90deg, #4C88EB, #3D70E7);
                border-radius: .36rem;
                text-align: center;
                line-height: .72rem;
                cursor: pointer;
                transition: filter 0.3s;
                margin: 0 .1rem;

                &:hover {
                    filter: brightness(1.1);
                }
            }
        }

    }


    .btn {

        width: 4.4rem;
        height: .72rem;
        line-height: .72rem;
        background: linear-gradient(90deg, #4C88EB, #3D70E7);
        border-radius: .36rem;
        font-size: .2rem;
        span-align: center;
        color: #FFFFFF;
        margin: .5rem auto 0;
        cursor: pointer;
        transition: filter 0.3s;

        &:hover {
            filter: brightness(1.1);
        }
    }
}
</style>

