<template>
    <div class="container">
        <div class="new">
            <div class="title">最新评价</div>
            <h2 v-if="list.length == 0">暂无评价</h2>
            <div class="text" v-if="list.length > 0">
                {{ list[0].content }}
            </div>
            <!-- <div class="text" v-if="list.length > 0">
                {{ list[0].content }}
            </div> -->
        </div>
        <div class="history">
            <div class="title">历史记录</div>
            <h2 v-if="list.length <= 1">暂无记录</h2>
            <div class="menu_list" v-for="item in list" :key="item.id">
                <div class="left">{{ item.evaluateDate.substr(5) }}：</div>
                <div class="right">
                    <div class="text">{{ item.content }}</div>
                    <!-- <div class="name">教授</div> -->
                </div>
            </div>
        </div>

        <!-- <div class="pagination" v-show="total > 0">
            <div class="center">
                <el-pagination background layout="prev,pager, next" :total="total" :current-page='queryParams.pageNum'
                    :page-size='queryParams.pageSize' @current-change="sizeChange">
                </el-pagination>
            </div>
        </div> -->
    </div>
</template>

<script>
import { queryStudentEvaluateList } from "@/api/mine";
export default {
    data() {
        return {
            list: [],
        }
    },
    mounted() {
        this.queryStudentEvaluateList()
    },
    methods: {
        async queryStudentEvaluateList() {
            const res = await queryStudentEvaluateList({});
            if (res.code == 200) {
                this.list = res.data;
                this.$parent.teacherEvaluationNum = res.data.length
            }
        }
    },
}
</script>
<style scoped lang="less">
::v-deep .el-pagination.is-background .el-pager li.active {
    background-color: #4C88EB !important;
    color: #fff !important;

    &:hover {
        color: #fff !important;
    }
}

::v-deep .el-pagination.is-background .el-pager li {
    width: .34rem;
    height: .34rem;
    border-radius: .06rem;
    text-align: center;
    line-height: .34rem;
    font-size: .16rem;
    background-color: #fff;
    border: .01rem solid #EEEEEE;
    font-family: 'PingFang SC';
    font-weight: 400;
}

::v-deep .el-pagination.is-background .btn-prev,
::v-deep .el-pagination.is-background .btn-next {
    width: .34rem;
    height: .34rem;
    border-radius: .06rem;
    text-align: center;
    line-height: .34rem;
    font-size: .16rem;
    background-color: #fff;
    border: .01rem solid #EEEEEE;
    font-family: 'PingFang SC';
    font-weight: 400;
}

::v-deep .el-pagination .btn-next .el-icon,
::v-deep .el-pagination .btn-prev .el-icon {
    font-size: .16rem;
}

.container {
    width: 100%;
    padding-top: .3rem;
    padding-bottom: .5rem;

    .new {
        width: 100%;
        background: rgba(255, 255, 255, 0);
        border: .01rem solid #EEEEEE;
        border-radius: .1rem;
        padding: .19rem .32rem .32rem;

        .title {
            font-size: .18rem;
            font-family: 'PingFang SC';
            font-weight: 700;
            color: #36393D;
            margin-bottom: .18rem;
        }

        .text {
            font-size: .16rem;
            font-family: 'PingFang SC';
            font-weight: 400;
            color: #36393D;
        }
    }

    .history {
        width: 100%;
        background: rgba(255, 255, 255, 0);
        border: .01rem solid #EEEEEE;
        border-radius: .1rem;
        padding: .19rem .32rem .32rem;
        margin-top: .2rem;

        .title {
            font-size: .18rem;
            font-family: 'PingFang SC';
            font-weight: 700;
            color: #36393D;
            margin-bottom: .18rem;
        }
    }

    .menu_list {
        margin-bottom: .2rem;
        display: flex;
        font-size: .16rem;
        font-family: 'PingFang SC';
        font-weight: 400;
        color: #36393D;
        margin-top: .17rem;

        .left {
            flex-shrink: 0;
        }

        .right {
            .text {}

            .name {
                color: #6D737A;
                margin-top: .17rem;
            }
        }
    }

    .pagination {
        display: block;
        margin-top: .6rem;

        .center {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

}
</style>
