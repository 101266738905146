<template>
    <div style="width: 100%;" class="home">
        <header>
            <homeHeader :active="2" />
        </header>
        <div class="content">
            <div class="title">
                <div class="left">
                    <img src="../static/img/icon_kclb.png">
                    <span>搜索结果</span>
                </div>
                <div class="right">
                    <el-dropdown trigger="click" @command="handleCommand">
                        <div class="btn1">
                            <span v-if="oneCategory.length == 0">课程分类</span>
                            <span v-else>{{ oneCategory }}</span>
                            <img src="../static/img/icon_xl.png" class="xl">
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item v-for="item in range" :key="item.id" :command="item.id">{{
                                    item.name
                                }}</el-dropdown-item>
                            </el-dropdown-menu>
                        </div>
                    </el-dropdown>
                    <el-dropdown trigger="click" @command="handleCommand2">
                        <div class="btn1" @click="checkCategory1">
                            <span v-if="twoCategory.length == 0">二级分类</span>
                            <span v-else>{{ twoCategory }}</span>
                            <img src="../static/img/icon_xl.png" class="xl">
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item v-for="item in secondRange" :key="item.id" :command="item.id">{{
                                    item.name
                                }}</el-dropdown-item>
                            </el-dropdown-menu>
                        </div>
                    </el-dropdown>
                    <div class="btn2" @click="reset">
                        <span>重置</span>
                    </div>
                </div>
            </div>
            <div class="list" v-loading.fullscreen.lock="loading">
                <div class="list_item" v-for="item, index in lessonList" :key="index" @click="toDetail(item.id)">
                    <img :src="'https://eeapi.appcloud.tech' + item.imageUrl" class="photo">
                    <div>
                        <div class="name">{{ item.name }}</div>
                        <div class="teacher">{{ item.teacherNames }}老师</div>
                        <div class="text">
                            <div class="left">已更新{{ item.chapterNum }}小节</div>
                        </div>
                    </div>
                </div>
                <div style="clear:both;"></div>
            </div>
        </div>
        <homeFooter />
    </div>
</template>

<script>
import { getToken } from '@/api/auth'
import homeHeader from '@/components/homeHeader.vue'
import homeFooter from '@/components/homeFooter.vue'
import { queryElectiveSubjectList, queryCategoryList } from "@/api/home";
export default {
    components: {
        homeHeader, homeFooter
    },
    data() {
        return {
            keyWord: '',
            oneCategoryId: '',
            twoCategoryId: '',
            oneCategory: '',
            twoCategory: '',
            lessonList: [],
            range: [],
            secondRange: [],
            loading: false,
            isLogin: false
        }
    },
    mounted() {
        this.keyWord = this.$route.query.keyWord
        this.queryCategoryList(0)
        this.queryElectiveSubjectList()
        let token = getToken()
        if (token) {
            this.isLogin = true
        } else {
            this.isLogin = false
        }
    },
    methods: {
        reset() {
            this.oneCategoryId = '';
            this.twoCategoryId = '';
            this.oneCategory = '';
            this.twoCategory = '';
            this.secondRange = [];
            this.queryElectiveSubjectList()
        },
        async queryCategoryList(e) {
            let res = await queryCategoryList({
                parentId: e
            })
            if (res.code == 200 && e == 0) {
                this.range = res.data
            } else if (res.code == 200) {
                this.secondRange = res.data
            }
        },
        handleCommand(id) {
            this.range.forEach((d) => {
                if (d.id == id) {
                    this.oneCategoryId = id;
                    this.oneCategory = d.name;
                    this.queryElectiveSubjectList()
                    this.queryCategoryList(id);
                }
            })
        },
        handleCommand2(id) {
            this.secondRange.forEach((d) => {
                if (d.id == id) {
                    this.twoCategoryId = id;
                    this.twoCategory = d.name;
                    this.queryElectiveSubjectList()
                }
            })
        },
        checkCategory1() {
            if (this.oneCategoryId.length == 0) {
                this.$message.error('请先选择一级分类')
            }
        },
        toDetail(id) {
            if (!this.isLogin) {
                this.$message.error('请先登录')
                setTimeout(() => {
                    this.$router.push({ path: '/login' }, onComplete => { }, onAbort => { })
                }, 1500);
            } else {
                this.$router.push({
                    path: '/lessonDetail',
                    query: { id }
                })
            }
        },
        async queryElectiveSubjectList() {
            try {
                this.loading = true
                let res = await queryElectiveSubjectList({
                    keyword: this.keyWord,
                    sortType: 1,
                    oneCategoryId: this.oneCategoryId,
                    twoCategoryId: this.twoCategoryId
                })
                this.loading = false
                if (res.code == 200) {
                    this.lessonList = res.rows;
                }
            } catch (error) {

            } finally {
                this.loading = false
            }
        }
    }
}
</script>
<style scoped lang="less">
::v-deep .el-dropdown-menu__item {
    width: 1.6rem !important;
    text-align: center;
}

header {
    width: 100%;
    height: .92rem;
}

.home {
    background: #f5f6f8;
    width: 100%;
}

.content {
    width: 14.78rem;
    margin: auto;
    padding-bottom: .5rem;

    .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: .39rem 0;

        .left {
            display: flex;
            align-items: center;

            img {
                width: .4rem;
                height: .4rem;
                margin-right: .07rem;
            }

            span {

                font-size: .3rem;
                font-family: 'pangmen';
                font-weight: 400;
                color: #2D2D2D;
            }
        }

        .right {
            display: flex;
            align-items: center;
        }

        .btn1 {
            padding: 0 .29rem;
            height: .5rem;
            background: #FFFFFF;
            box-shadow: 0px 0px .12rem 0px rgba(0, 0, 0, 0.3);
            border-radius: .25rem;
            font-size: .18rem;
            font-family: PingFang SC;
            font-weight: 400;
            color: #36393D;
            text-align: center;
            line-height: .5rem;
            cursor: pointer;
            margin-left: .2rem;

            img {
                width: .12rem;
                height: .12rem;
                margin-left: .19rem;
            }
        }

        .btn2 {
            width: 1.43rem;
            height: .5rem;
            background: linear-gradient(90deg, #4C88EB, #3D70E7);
            box-shadow: 0px 0px .12rem 0px rgba(0, 0, 0, 0.3);
            border-radius: .25rem;
            font-size: .18rem;
            font-family: PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            text-align: center;
            line-height: .5rem;
            cursor: pointer;
            margin-left: .2rem;
            transition: filter 0.3s;

            &:hover {
                filter: brightness(1.1);
            }
        }
    }

    .list {

        .list_item {
            width: 3.47rem;
            height: 3.6rem;
            background: #FFFFFF;
            box-shadow: 0 0 .2rem 0 rgba(0, 0, 0, 0.3);
            border-radius: .1rem;
            display: inline-block;
            float: left;
            margin-right: .3rem;
            margin-bottom: .3rem;
            overflow: hidden;
            position: relative;
            cursor: pointer;
            transition: transform .3s;

            &:hover {
                transform: scale(1.03);
            }

            &:nth-child(4n) {
                margin-right: 0;
            }

            .photo {
                width: 100%;
                height: 1.96rem;
                background: #eee;
            }

            >div {
                padding: .19rem .2rem;
                box-sizing: border-box;
                width: 100%;

                .name {
                    font-size: .2rem;
                    font-family: 'PingFang SC';
                    font-weight: 700;
                    color: #36393D;
                }

                .teacher {
                    font-size: .16rem;
                    font-family: 'PingFang SC';
                    font-weight: 400;
                    color: #6D737A;
                    margin: .19rem 0 .2rem;
                }

                .text {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .left {
                        font-size: .16rem;
                        font-family: 'PingFang SC';
                        font-weight: 400;
                        color: #4C88EB;
                    }

                    .right {
                        font-size: .16rem;
                        font-family: OPPOSans;
                        font-weight: 800;
                        color: #FF7800;
                    }
                }

                .bottom {
                    position: absolute;
                    left: .2rem;
                    bottom: .2rem;
                    display: flex;
                    align-items: center;

                    img {
                        width: .162rem;
                        height: .162rem;
                        margin-right: .07rem;
                    }

                    font-size: .16rem;
                    font-family: 'PingFang SC';
                    font-weight: 400;
                    color: #979EA7;
                }
            }
        }
    }
}
</style>

