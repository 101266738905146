<template>
    <div style="width: 100%;" class="container">
        <header>
            <homeHeader />
        </header>
        <div class="content">
            <div class="nav">
                <div :class="navActive == 1 ? 'nav_item active' : 'nav_item'" style="border-top-right-radius:.16rem ;"
                    @click="changeNav(1)">
                    <div class="img1"></div>
                    <p>我的班级</p>
                </div>
                <div :class="navActive == 2 ? 'nav_item active' : 'nav_item'" @click="changeNav(2)">
                    <div class="img2"></div>
                    <p>考试记录</p>
                </div>
                <div :class="navActive == 3 ? 'nav_item active' : 'nav_item'" @click="changeNav(3)">
                    <div class="img3"></div>
                    <p>作业记录</p>
                </div>
                <div :class="navActive == 4 ? 'nav_item active' : 'nav_item'" @click="changeNav(4)">
                    <div class="img4"></div>
                    <p>自我评价</p>
                </div>
                <div :class="navActive == 5 ? 'nav_item active' : 'nav_item'" @click="changeNav(5)">
                    <div class="img5"></div>
                    <p>教师评价</p>
                </div>
                <div :class="navActive == 6 ? 'nav_item active' : 'nav_item'" @click="changeNav(6)">
                    <div class="img6"></div>
                    <p>设置</p>
                </div>
            </div>
            <div class="main">
                <div class="top">
                    <div class="title">
                        <span v-if="navActive == 1">我的班级</span>
                        <span v-if="navActive == 2">考试记录</span>
                        <span v-if="navActive == 3">作业记录</span>
                        <span v-if="navActive == 4">自我评价</span>
                        <span v-if="navActive == 5">教师评价</span>
                        <span v-if="navActive == 6">设置</span>
                    </div>
                    <div class="msg">
                        <span v-if="navActive == 1">我所在班级情况</span>
                        <span v-if="navActive == 2">我的考试记录</span>
                        <span v-if="navActive == 3">我的作业记录</span>
                        <span v-if="navActive == 4">对本人进行全面评价</span>
                        <span v-if="navActive == 5">我的教师评价</span>
                        <span v-if="navActive == 6">请设置以下内容</span>
                    </div>
                    <div class="teacherEva" v-if="navActive == 5">
                        累计评价 （{{ teacherEvaluationNum }}次）
                    </div>
                </div>
                <div class="line"></div>
                <div class="bottom">
                    <myClass v-if="navActive == 1" />
                    <myExam v-if="navActive == 2" />
                    <workListRecord v-if="navActive == 3" />
                    <myEvaluation v-if="navActive == 4" />
                    <teacherEvaluation v-if="navActive == 5" />
                    <set v-if="navActive == 6" />
                </div>
            </div>
            <div class="right">
                <img :src="'https://eeapi.appcloud.tech' + userInfo.headImg" class="head" v-if="hasHeadImg">
                <img src="../static/img/head.png" class="head" v-else>
                <div class="btn" @click="open">个人资料</div>
                <div class="bottom">
                    <div class="nav1" @click="$router.push({ path: '/courseList' }, onComplete => { }, onAbort => { })">
                        <div class="num num1">{{ lessonNum }}</div>
                        <div class="text">我的课程</div>
                    </div>
                    <div class="nav1" @click="$router.push({ path: '/myCollect' }, onComplete => { }, onAbort => { })">
                        <div class="num num2">{{ collectNum }}</div>
                        <div class="text">我的收藏</div>
                    </div>
                </div>
            </div>
            <el-dialog :visible.sync="dialogVisible" :show-close="false" :close-on-click-modal="false">
                <div class="dialog">
                    <div class="title">个人信息</div>
                    <el-upload class="avatar-uploader" action="https://eeapi.appcloud.tech/prod-api/api/common/upload"
                        :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                        <div class="top">
                            <img :src="imageUrl" class="head" v-if="imageUrl">
                            <img :src="'https://eeapi.appcloud.tech' + userInfo.headImg" class="head"
                                v-else-if="hasHeadImg">
                            <img src="../static/img/head.png" class="head" v-else>
                            <div class="upload">上传头像</div>
                        </div>
                    </el-upload>
                    <div class="section">
                        <el-form :model="form1" :rules="rules" ref="ruleForm" class="demo-ruleForm">
                            <el-row>
                                <el-col :span="13">
                                    <el-form-item label="" prop="nickName">
                                        <div class="formItem">
                                            <div class="text">昵称</div>
                                            <el-input type="text" placeholder="请输入昵称" v-model="form1.nickName" />
                                        </div>
                                    </el-form-item>
                                    <el-form-item label="" prop="gender">
                                        <el-dropdown trigger="click" @command="handleCommand">
                                            <div class="formItem">
                                                <div class="text">性别</div>
                                                <el-input placeholder="请选择性别" v-model="form1.gender" readonly />
                                                <el-dropdown-menu slot="dropdown">
                                                    <el-dropdown-item :command="0">男</el-dropdown-item>
                                                    <el-dropdown-item :command="1">女</el-dropdown-item>
                                                </el-dropdown-menu>
                                            </div>
                                        </el-dropdown>
                                        <img src="../static/img/icon_xl.png" class="xl">
                                    </el-form-item>
                                    <el-form-item label="" prop="phone">
                                        <div class="formItem">
                                            <div class="text">账号</div>
                                            <el-input type="text" disabled v-model="$store.state.userInfo.phone" />
                                        </div>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="11">
                                    <el-form-item label="" prop="loginPwd">
                                        <div class="formItem">
                                            <div class="text">姓名</div>
                                            <el-input type="text" disabled v-model="$store.state.userInfo.realName" />
                                        </div>
                                    </el-form-item>
                                    <el-form-item label="" prop="birthday">
                                        <div class="formItem">
                                            <div class="text">生日</div>
                                            <el-date-picker v-model="form1.birthday" type="date" placeholder="请选择日期"
                                                value-format="yyyy-MM-dd">
                                            </el-date-picker>
                                        </div>
                                        <img src="../static/img/icon_xl.png" class="xl">
                                    </el-form-item>
                                    <el-form-item label="" prop="className">
                                        <div class="formItem">
                                            <div class="text">班级</div>
                                            <el-input v-model="$store.state.userInfo.classesName" disabled />
                                        </div>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-form>
                    </div>
                    <div class="btns">
                        <div class="qx" @click="dialogVisible = false">取消</div>
                        <el-button class="qd" type="primary" @click="save()" :loading="loading">
                            <span>保存</span>
                        </el-button>
                    </div>
                </div>
            </el-dialog>
            <!-- <userAvatar /> -->
        </div>

        <homeFooter />
    </div>
</template>

<script>
import homeHeader from '@/components/homeHeader.vue'
import homeFooter from '@/components/homeFooter.vue'
import userAvatar from './userAvatar.vue'
import myClass from '@/components/userCenter/myClass.vue'
import myExam from '@/components/userCenter/myExam.vue'
import myEvaluation from '@/components/userCenter/myEvaluation.vue'
import workListRecord from '@/components/userCenter/workListRecord.vue'
import teacherEvaluation from '@/components/userCenter/teacherEvaluation.vue'
import set from '@/components/userCenter/set.vue'
import { updateStudentInfo, getLoginStudentInfo, queryStudentClassesSubjectList, queryCollectSubjectList } from "@/api/mine";
export default {
    components: {
        homeHeader,
        homeFooter,
        myClass,
        myExam,
        myEvaluation,
        workListRecord,
        teacherEvaluation,
        set
    },
    data() {
        return {
            imageUrl: '',
            navActive: 1,
            dialogVisible: false,
            loading: false,
            hasHeadImg: false,
            rules: {
                birthday: [
                    { required: true, message: '请选择生日', trigger: ['blur', 'change'] }
                ],
                nickName: [
                    { required: true, message: '请输入昵称', trigger: ['blur', 'change'] }
                ],
                gender: [
                    { required: true, message: '请选择性别', trigger: ['blur', 'change'] }
                ]
            },
            form1: {
                birthday: "",
                headImg: "",
                nickName: "",
                gender: '',
                sex: ""
            },
            userInfo: {},
            canJoinClasses: [],
            collectNum: '',
            lessonNum: '',
            teacherEvaluationNum: 0
        }
    },
    mounted() {
        this.navActive = this.$route.query.nav ? this.$route.query.nav : 1
        this.getLoginStudentInfo()
        this.queryStudentClassesSubjectList()
        this.queryCollectSubjectList()
    },
    beforeDestroy() {
    },
    methods: {
        async queryStudentClassesSubjectList() {
            let res = await queryStudentClassesSubjectList({});
            if (res.code == 200) {
                this.lessonNum = res.data ? res.data.length : 0
            }
        },
        async queryCollectSubjectList() {
            let res = await queryCollectSubjectList({});
            if (res.code == 200) {
                let collectNum = 0;
                if (res.data) {
                    res.data.forEach((d) => {
                        collectNum += d.subjectList ? d.subjectList.length : 0
                    })
                }
                this.collectNum = collectNum
            }
        },
        open() {
            this.dialogVisible = true;
            this.getLoginStudentInfo()
        },
        async getLoginStudentInfo() {
            let res = await getLoginStudentInfo({});
            this.userInfo = res.data
            localStorage.setItem('eduUserInfo', JSON.stringify(res.data))
            this.$store.dispatch('SET_USER_INFO', res.data)
            this.form1 = {
                birthday: this.userInfo.birthday || '',
                headImg: this.userInfo.headImg || '',
                nickName: this.userInfo.nickName || '',
                sex: this.userInfo.sex || '',
                gender: this.userInfo.sex == 0 ? '男' : this.userInfo.sex == 1 ? '女' : ''
            }
            if (this.userInfo.headImg) {
                this.hasHeadImg = true
            }
        },
        handleCommand(id) {
            if (id == '0') {
                this.form1.gender = '男'
                this.form1.sex = 0
            } else if (id == '1') {
                this.form1.gender = '女'
                this.form1.sex = 1
            } else {
                this.form1.gender = ''
            }
        },
        handleAvatarSuccess(res, file) {
            this.imageUrl = URL.createObjectURL(file.raw);
            this.form1.headImg = res.fileName;
        },
        beforeAvatarUpload(file) {
            const isJPG = file.type === 'image/jpeg';
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
                this.$message.error('上传头像图片只能是 JPG 格式!');
            }
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 2MB!');
            }
            return isJPG && isLt2M;
        },
        changeNav(nav) {
            this.navActive = nav
        },
        save() {
            this.$refs['ruleForm'].validate(async (valid) => {
                if (valid) {
                    if (this.imageUrl.length == 0) {
                        delete this.form1.headImg
                    }
                    try {
                        this.loading = true;
                        let res = await updateStudentInfo(this.form1);
                        if (res.code == 200) {
                            this.$message.success('保存成功');
                            if (this.navActive == 1) {
                                this.navActive = 0;
                                setTimeout(() => {
                                    this.navActive = 1;
                                }, 100);
                            }
                            this.getLoginStudentInfo()
                            this.dialogVisible = false
                        }
                        this.loading = false;
                    } catch (error) {
                        this.loading = false;
                    }

                } else {
                    return false;
                }
            });
        }
    }
}
</script>
<style scoped lang="less">
::v-deep .el-dropdown-menu__item {
    width: 3.75rem !important;
}

::v-deep .el-dialog__header {
    display: none;
}

::v-deep .el-dialog__body {
    padding: 0;
}

::v-deep .el-dialog {
    border-radius: .16rem;
    overflow: hidden;
}


::v-deep .formItem .el-input__inner {
    width: 3.75rem;
    height: .5rem;
    background-color: #F7F8F9 !important;
    background: #F7F8F9;
    border-radius: .06rem;
    border: 0;
    outline: 0;
    padding-left: .2rem;
    padding-right: .26rem;
    font-size: .18rem;

    &::placeholder {
        color: #BEC2C7;
    }
}

header {
    width: 100%;
    height: .92rem;
}

.content {
    background: #f5f6f8;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: .3rem 0 .8rem;

    .dialog {
        width: 9.3rem;
        background: #FFFFFF;
        padding: 0.5rem 0 0.5rem 0.5rem;
        box-sizing: border-box;

        .title {
            text-align: center;
            width: 100%;
            font-size: .36rem;
            font-family: 'PingFang SC';
            font-weight: 500;
            color: #36393D;
        }

        .top {
            display: flex;
            align-items: center;
            margin-top: .5rem;

            .head {
                width: 1.2rem;
                height: 1.2rem;
                background: #fff;
                border-radius: 50%;
                overflow: hidden;
                border: .01rem solid #ccc;
                margin-right: .4rem;
            }

            .upload {
                width: 1.43rem;
                height: .48rem;
                background: #EDF2F6;
                border-radius: .24rem;
                font-size: .18rem;
                font-family: 'PingFang SC';
                font-weight: 400;
                color: #36393D;
                text-align: center;
                line-height: .48rem;
                cursor: pointer;
                transition: filter 0.3s;

                &:hover {
                    filter: brightness(1.02);
                }
            }
        }

        .formItem {
            position: relative;
            margin-top: .2rem;

            .text {
                font-size: .18rem;
                font-family: 'PingFang SC';
                font-weight: 400;
                color: #36393D;
            }

            ::v-deep .el-input__icon {
                display: none;
            }
        }

        .btns {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: .3rem;

            .qx {
                width: 1.43rem;
                height: .48rem;
                border: .01rem solid #DDDDDD;
                border-radius: .24rem;
                font-size: .18rem;
                font-family: 'PingFang SC';
                font-weight: 400;
                color: #36393D;
                text-align: center;
                line-height: .48rem;
                margin: 0 .15rem;
                cursor: pointer;
                transition: filter 0.3s;

                &:hover {
                    filter: brightness(1.01);
                }
            }

            .qd {
                width: 1.43rem;
                height: .48rem;
                background: linear-gradient(90deg, #4C88EB, #3D70E7);
                border-radius: .24rem;
                font-size: .18rem;
                font-family: 'PingFang SC';
                font-weight: 400;
                color: #FFFFFF;
                text-align: center;
                margin: 0 .15rem;
                cursor: pointer;
                transition: filter 0.3s;

                &:hover {
                    filter: brightness(1.1);
                }
            }
        }

        .xl {
            position: absolute;
            z-index: 999;
            left: 3.31rem;
            bottom: .18rem;
        }


    }

    .nav {
        width: 1.21rem;
        height: 7.26rem;
        background: #FFFFFF;
        box-shadow: 0rem 0rem .2rem 0rem rgba(0, 0, 0, 0.16);
        border-radius: .16rem;
        box-sizing: border-box;

        .nav_item {
            width: 1.21rem;
            height: .9rem;
            background: #fff;
            box-sizing: border-box;
            overflow: hidden;
            cursor: pointer;
            transition: all 0.1s;

            &:hover {
                background: #EFF4FD;
                border-left: .02rem solid rgba(76, 136, 235, 1);

                p {
                    color: #4C88EB;
                }

                .img1 {
                    background-image: url(../static/img/icon_wdbj_s.png);
                }

                .img2 {
                    background-image: url(../static/img/icon_ksjl_s.png);
                }

                .img3 {
                    background-image: url(../static/img/icon_zyjl_s.png);
                }

                .img4 {
                    background-image: url(../static/img/icon_zwpj_s.png);
                }

                .img5 {
                    background-image: url(../static/img/icon_jspj_s.png);
                }

                .img6 {
                    background-image: url(../static/img/icon_sz_s.png);
                }
            }


            >div {
                width: .4rem;
                height: .4rem;
                margin: .12rem auto 0;
                background-size: 100% 100%;
            }

            .img1 {
                background-image: url(../static/img/icon_wdbj.png);
            }

            .img2 {
                background-image: url(../static/img/icon_ksjl.png);
            }

            .img3 {
                background-image: url(../static/img/icon_zyjl.png);
            }

            .img4 {
                background-image: url(../static/img/icon_zwpj.png);
            }

            .img5 {
                background-image: url(../static/img/icon_jspj.png);
            }

            .img6 {
                background-image: url(../static/img/icon_sz.png);
            }

            p {
                text-align: center;
                width: 100%;
                font-size: .18rem;
                font-family: 'PingFang SC';
                font-weight: 400;
                color: #6D737A;
            }
        }

        .nav_item.active {
            width: 1.21rem;
            height: .9rem;
            background: #EFF4FD;
            border-left: .02rem solid rgba(76, 136, 235, 1);
            box-sizing: border-box;
            overflow: hidden;

            >div {
                width: .4rem;
                height: .4rem;
                margin: .12rem auto 0;
                background-size: 100% 100%;
            }

            .img1 {
                background-image: url(../static/img/icon_wdbj_s.png);
            }

            .img2 {
                background-image: url(../static/img/icon_ksjl_s.png);
            }

            .img3 {
                background-image: url(../static/img/icon_zyjl_s.png);
            }

            .img4 {
                background-image: url(../static/img/icon_zwpj_s.png);
            }

            .img5 {
                background-image: url(../static/img/icon_jspj_s.png);
            }

            .img6 {
                background-image: url(../static/img/icon_sz_s.png);
            }

            p {
                text-align: center;
                width: 100%;
                font-size: .18rem;
                font-family: 'PingFang SC';
                font-weight: 400;
                color: #4C88EB;
            }
        }
    }

    .main {
        width: 9.07rem;
        background: #FFFFFF;
        box-shadow: 0rem 0rem .2rem 0rem rgba(0, 0, 0, 0.16);
        border-radius: .16rem;
        margin: 0 .2rem;
        padding: .21rem .31rem;
        box-sizing: border-box;
        position: relative;

        .top {
            .title {
                font-size: .22rem;
                font-family: 'PingFang SC';
                font-weight: 700;
                color: #4C88EB;
            }

            .msg {
                font-size: .18rem;
                font-family: 'PingFang SC';
                font-weight: 400;
                color: #6D737A;
                margin: .15rem 0 .2rem;
            }
        }

        .teacherEva {
            position: absolute;
            right: .29rem;
            top: .20rem;
            font-size: .22rem;
            font-family: 'PingFang SC';
            font-weight: 500;
            color: #4C88EB;
        }

        .line {
            width: 100%;
            height: .01rem;
            background: #EEEEEE;
        }
    }

    .right {
        width: 4.12rem;
        height: 4.8rem;
        background: #FFFFFF;
        box-shadow: 0rem 0rem .2rem 0rem rgba(0, 0, 0, 0.16);
        border-radius: .16rem;

        .head {
            display: block;
            width: 1.42rem;
            height: 1.42rem;
            margin: .68rem auto .3rem;
            border-radius: 50%;
            overflow: hidden;
            border: .01rem solid #ccc;
        }

        .btn {
            width: 1.43rem;
            height: .48rem;
            background: linear-gradient(90deg, #4C88EB, #3D70E7);
            border-radius: .24rem;
            font-size: .18rem;
            font-family: 'PingFang SC';
            font-weight: 400;
            color: #FFFFFF;
            text-align: center;
            line-height: .48rem;
            margin: auto;
            cursor: pointer;
            transition: filter 0.3s;

            &:hover {
                filter: brightness(1.1);
            }
        }

        .bottom {
            display: flex;
            padding: 0 .3rem;

            .nav1 {
                width: 50%;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                margin-top: .4rem;
                transition: transform 0.3s;
                cursor: pointer;

                &:hover {
                    transform: scale(1.1);
                }

                .num {
                    width: 100%;
                    text-align: center;
                    font-size: .32rem;
                    font-family: DIN;
                    font-weight: bold;
                    color: #4C88EB;
                    margin-bottom: .16rem;
                }

                .num1 {
                    color: #4C88EB;
                }

                .num2 {
                    color: #FF7800;
                }

                .text {
                    width: 100%;
                    text-align: center;
                    font-size: .2rem;
                    font-family: 'PingFang SC';
                    font-weight: 400;
                    color: #36393D;
                }
            }
        }
    }
}
</style>

