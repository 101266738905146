<template>
    <div class="container">
        <h2 v-if="total == 0">暂无记录</h2>
        <div class="menu_list" v-for="item in menu" :key="item.id">
            <div class="defen">
                得分：<span v-if="item.correctStatus != 1">{{ item.score }} 分</span><span v-else
                    style="color: #6D737A">待批改</span>
            </div>
            <div class="taskName">
                {{ item.examName }}
            </div>
            <div class="history">
                考试时间： {{ item.startTime }}
            </div>
        </div>

        <div class="pagination" v-show="total > 0">
            <div class="center">
                <el-pagination background layout="prev,pager, next" :total="total" :current-page='queryParams.pageNum'
                    :page-size='queryParams.pageSize' @current-change="sizeChange">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import { queryStudentExamList } from "@/api/mine";
export default {
    data() {
        return {
            queryParams: {
                pageSize: 3,
                pageNum: 1,
                status: 2
            },
            total: 0,
            menu: [],
        }
    },
    mounted() {
        this.queryStudentExamList()
    },
    methods: {
        async queryStudentExamList() {
            const res = await queryStudentExamList(this.queryParams);
            if (res.code == 200) {
                this.menu = res.rows;
                this.total = res.total
            }
        },
        sizeChange(e) {
            this.queryParams.pageNum = e;
            this.queryStudentExamList()
        }
    },
}
</script>
<style scoped lang="less">
::v-deep .el-pagination.is-background .el-pager li.active {
    background-color: #4C88EB !important;
    color: #fff !important;

    &:hover {
        color: #fff !important;
    }
}

::v-deep .el-pagination.is-background .el-pager li {
    width: .34rem;
    height: .34rem;
    border-radius: .06rem;
    text-align: center;
    line-height: .34rem;
    font-size: .16rem;
    background-color: #fff;
    border: .01rem solid #EEEEEE;
    font-family: 'PingFang SC';
    font-weight: 400;
}

::v-deep .el-pagination.is-background .btn-prev,
::v-deep .el-pagination.is-background .btn-next {
    width: .34rem;
    height: .34rem;
    border-radius: .06rem;
    text-align: center;
    line-height: .34rem;
    font-size: .16rem;
    background-color: #fff;
    border: .01rem solid #EEEEEE;
    font-family: 'PingFang SC';
    font-weight: 400;
}

::v-deep .el-pagination .btn-next .el-icon,
::v-deep .el-pagination .btn-prev .el-icon {
    font-size: .16rem;
}

.container {
    width: 100%;
    padding-top: .3rem;
    padding-bottom: 0.2rem;

    .menu_list {
        margin-bottom: .2rem;
        padding: .2rem .3rem;
        width: 100%;
        position: relative;
        background: rgba(255, 255, 255, 0);
        border: .01rem solid #EEEEEE;
        border-radius: .1rem;

        .defen {
            font-size: .18rem;
            font-family: 'PingFang SC';
            font-weight: 500;
            color: #36393D;

            span {
                color: #4C88EB;
            }
        }

        .taskName {

            font-size: .16rem;
            font-family: 'PingFang SC';
            font-weight: 400;
            color: #36393D;
            margin: .1rem 0;
            white-space: nowrap;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .history {
            font-size: .16rem;
            font-family: 'PingFang SC';
            font-weight: 400;
            color: #6D737A;
        }
    }

    .pagination {
        display: block;
        margin-top: .6rem;

        .center {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

}
</style>
