<template>
    <div class="workListRecord">
        <h2 v-if="total == 0">暂无记录</h2>
        <div class="menu_list" v-for="item in menu" :key="item.id">
            <div class="" style="display: flex;justify-content: space-between;">
                <div class="menu_list_left">
                    <div class="tops">
                        <div class="">
                            {{ item.taskName }}
                        </div>
                    </div>
                    <div class="bottoms" v-if="item.type != 1">
                        <img src='../../static/img/icon_time.png' class="icons1" />
                        <div class="">
                            截止日期：{{ item.endTime }}
                        </div>
                    </div>
                </div>
                <div class="fenshu" v-if="item.status == 1 && item.score">
                    得分：{{ item.score }}
                </div>
                <div class="yuqi" v-if="item.overtime">
                    逾期未提交
                </div>
                <div class="btns" @click="toWorkDetail(item.id, item.type)" v-if="item.status != 1 && !item.overtime">
                    开始
                </div>
                <div class="btns2" v-if="item.status == 1">
                    已完成
                </div>
            </div>
        </div>

        <div class="pagination">
            <div class="center">
                <el-pagination background layout="prev,pager, next" :total="total" :current-page='queryParams.pageNum'
                    :page-size='queryParams.pageSize' @current-change="sizeChange">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import { queryStudentTaskList } from "@/api/mine";
export default {
    data() {
        return {
            queryParams: {
                pageSize: 3,
                pageNum: 1,
                status: -1
            },
            total: 0,
            menu: [],
        }
    },
    mounted() {
        this.queryStudentTaskList()
    },
    methods: {
        async queryStudentTaskList(status) {
            const res = await queryStudentTaskList(this.queryParams);
            if (res.code == 200) {
                this.menu = res.rows;
                this.total = res.total
            }
        },
        sizeChange(e) {
            this.queryParams.pageNum = e;
            this.queryStudentTaskList()
        },
        toWorkDetail(id, type) {
            if (type == 2) {
                this.$router.push({
                    path: '/beginWork',
                    query: {
                        id,
                        type
                    }
                })
            } else if (type == 1 || type == 3) {
                this.$router.push({
                    path: '/workDetail',
                    query: {
                        id
                    }
                })
            }
        },
    },
}
</script>
<style scoped lang="less">
::v-deep .el-pagination.is-background .el-pager li.active {
    background-color: #4C88EB !important;
    color: #fff !important;

    &:hover {
        color: #fff !important;
    }
}

::v-deep .el-pagination.is-background .el-pager li {
    width: .34rem;
    height: .34rem;
    border-radius: .06rem;
    text-align: center;
    line-height: .34rem;
    font-size: .16rem;
    background-color: #fff;
    color: #36393D;
    border: .01rem solid #EEEEEE;
    font-family: 'PingFang SC';
    font-weight: 400;
}

::v-deep .el-pagination.is-background .btn-prev,
::v-deep .el-pagination.is-background .btn-next {
    width: .34rem;
    height: .34rem;
    border-radius: .06rem;
    text-align: center;
    line-height: .34rem;
    font-size: .16rem;
    background-color: #fff;
    color: #36393D;
    border: .01rem solid #EEEEEE;
    font-family: 'PingFang SC';
    font-weight: 400;
}

::v-deep .el-pagination .btn-next .el-icon,
::v-deep .el-pagination .btn-prev .el-icon {
    font-size: .16rem;
}

.workListRecord {
    width: 100%;
    padding-top: .3rem;
    padding-bottom: .5rem;

    .menu_list {
        margin-bottom: .2rem;
        padding: .24rem .3rem;
        width: 100%;
        height: 1rem;
        position: relative;
        background: rgba(255, 255, 255, 0);
        border: .01rem solid #EEEEEE;
        border-radius: .1rem;

        .fenshu {
            line-height: .52rem;
            font-size: .18rem;
            font-family: 'PingFang SC';
            font-weight: 500;
            color: #4C88EB;
            margin-right: 1rem;
        }

        .yuqi {
            font-size: .18rem;
            font-family: 'PingFang SC';
            font-weight: 500;
            color: #FF7800;
            line-height: .52rem;

        }

        .btns,
        .btns3 {
            width: 1.2rem;
            height: .48rem;
            flex-shrink: 0;
        }

        .btns {
            border: .01rem solid #4C88EB;
            border-radius: .24rem;
            font-size: .18rem;
            font-family: 'PingFang SC';
            font-weight: 400;
            color: #4C88EB;
            line-height: .48rem;
            text-align: center;
            cursor: pointer;
        }

        .btns2 {
            width: 1.2rem;
            height: .52rem;
            flex-shrink: 0;
            line-height: .52rem;
            font-size: .18rem;
            font-family: 'PingFang SC';
            font-weight: 400;
            color: #0DC712;
            text-align: center;
            flex-shrink: 0;
        }

        .btns3 {
            border-radius: .3rem;
            border: .01rem solid #f69b2d;
            color: #f69b2d;
            text-align: center;
            line-height: .57rem;
            font-size: .26rem
        }

        .menu_list_left {
            width: 50%;
            font-size: .18rem;
            // display: flex;
            // flex-wrap: wrap;

            .tops,
            .bottoms {
                display: flex;
                width: 65%;
                align-items: center
            }

            .tops {
                font-weight: bold;
                width: 100%;

                >div {
                    white-space: nowrap;
                    width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            .bottoms {
                width: 100%;
                font-size: .16rem;
                margin-top: .14rem;
                color: #999;

                .icons1 {
                    width: .2rem;
                    height: .2rem;
                    margin-right: .11rem;
                }
            }

        }
    }

    .pagination {
        display: block;
        margin-top: .6rem;

        .center {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

}
</style>
