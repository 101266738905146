import Vue from 'vue'
import Vuex from 'vuex'
import { getToken, setToken, removeToken } from '../api/auth'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: '',
    isLogin: false,
    userInfo: {}
  },
  getters: {
  },
  mutations: {
    //设置用户信息
    SET_USER_INFO(state, userInfo) {
      state.userInfo = userInfo
    },
    //设置token
    SET_TOKEN(state, token) {
      state.token = token
      state.isLogin = true
    },
    //设置登录状态
    SET_LOGIN_STATE(state, status) {
      state.isLogin = status
    },
    CLEAR(state, v) {
      state.email = '';
      state.token = '';
      state.isLogin = false;
      state.userInfo = {};
      removeToken()
    },
  },
  actions: {
    async nuxtServerInit({ commit }, { app }) {
      var token = ''
      let userInfo = ''
      // 从cookie中获取token，并且将其中的数据更新到store
      token = app.$cookies.get('token')
      userInfo = app.$cookies.get('userInfo')
      // console.log(token, userInfo)
      if (token && userInfo) {
        commit('SET_TOKEN', token)
        commit('SET_USER_INFO', userInfo)
      } else {
        commit('SET_TOKEN', '')
        commit('SET_USER_INFO', '')
        commit('SET_LOGIN_STATE', false)
      }
    },
    //设置token
    SET_TOKEN({ commit }, token) {
      setToken(token)
      commit('SET_TOKEN', token)
    },
    //设置用户信息
    SET_USER_INFO({ commit }, userInfo) {
      commit('SET_USER_INFO', userInfo)
    },
    SET_LOGIN_STATE({ commit }, status) {
      commit('SET_LOGIN_STATE', status)
    },
    CLEAR({ commit }) {
      commit('CLEAR')
    },
  },
  modules: {
  }
})
