<template>
    <div style="width: 100%;" class="workDetail">
        <header>
            <homeHeader :active="2" />
        </header>
        <div class="content">
            <div class="back" @click="back()">
                <img src="../static/img/icon_back2.png">返回
            </div>
            <div class="section">
                <div class="top">
                    <div class="left">
                        <div class="name" style="font-weight: bold;">{{ taskInfo.taskName }}</div>
                        <div class="jiezhi">截止日期：{{ taskInfo.endTime }}</div>
                    </div>
                    <div class="right">
                        <div class="status1" v-if="taskInfo.status != 1">未完成</div>
                        <div class="status2" v-else>已完成</div>
                    </div>
                </div>
                <div class="detail">
                    <div class="list">
                        <div class="title">
                            {{ taskInfo.taskCustom.content }}
                        </div>
                        <div class="" v-for="(item, i) in imageUrl" :key="i">
                            <img :src="getProfilePath(item)" class="image_" @click="clickImg(item)" />
                        </div>
                    </div>
                </div>
                <div class="">
                    <div class="t" style="font-weight: bold;">
                        我的作答
                    </div>
                    <div>
                        <el-input type="textarea" :rows="6" placeholder="请输入内容" v-model="answer">
                        </el-input>
                    </div>
                    <div class="image_" style="margin-top: .3rem;">
                        <el-upload action="https://eeapi.appcloud.tech/prod-api/api/common/upload" list-type="picture-card"
                            :on-preview="handlePictureCardPreview" :on-success="picChange" :on-remove="handleRemove">
                            <i class="el-icon-plus"></i>
                        </el-upload>
                        <el-dialog :visible.sync="dialogVisible">
                            <img width="100%" :src="dialogImageUrl" alt="">
                        </el-dialog>
                    </div>
                </div>
            </div>
            <div class="btn" @click="qxModal = true" v-if="taskInfo.status == 0">
                <span>提交作业</span>
            </div>
            <div class="btn" @click="back" v-if="taskInfo.status == 1">
                <span>返回</span>
            </div>
            <el-dialog title="" :visible.sync="qxModal" width="26%">
                <h2 style="width: 100%;text-align: center;">确认提交后,作业将提交给老师</h2>
                <div class="btn2">
                    <div class="qx" @click="qxModal = false">取消</div>
                    <el-button class="qd" type="primary" @click="submitWork()">
                        确认
                    </el-button>
                </div>
            </el-dialog>
        </div>
        <homeFooter style="margin-top: .8rem;" />
    </div>
</template>

<script>
import homeHeader from '@/components/homeHeader.vue'
import homeFooter from '@/components/homeFooter.vue'
import { queryStudentTaskInfo, submitCustomTask } from "@/api/home";
export default {
    components: {
        homeHeader, homeFooter
    },
    data() {
        return {
            id: '',
            begin: false,
            showModal: false,
            result: false,
            answer: '',
            taskInfo: [],
            fileLists: [],
            urlList: [],
            imageUrl: [],
            dialogVisible: false,
            dialogImageUrl: '',
            qxModal: false
        }
    },
    mounted() {
        this.id = this.$route.query.id
        this.queryStudentTaskInfo(this.id)
    },
    beforeDestroy() {
    },
    methods: {
        back() {
            window.history.back(-1);
            setTimeout(() => {
                if (this.$route.path == '/beginWork') {
                    this.back()
                }
            }, 100);
        },
        handleRemove(file, fileList) {
            this.fileLists.forEach((d, index) => {
                if (d.uid == file.uid) {
                    this.fileLists.splice(index, 1);
                    this.urlList.splice(index, 1);
                }
            });
        },
        picChange(response, file, fileList) {
            this.fileLists = [...fileList];
            this.urlList.push(response.fileName)
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        clickImg(urls) {
            let url = 'https://eeapi.appcloud.tech' + urls
            this.dialogImageUrl = url;
            this.dialogVisible = true;
        },
        async queryStudentTaskInfo(id) {
            let res = await queryStudentTaskInfo({
                id
            })
            if (res.code == 200) {
                this.taskInfo = res.data;
                let imageUrl = res.data.taskCustom.imageUrl;
                this.imageUrl = imageUrl ? imageUrl.split(',') : []
                this.begin = false
            }
        },
        async submitWork() {
            let res = await submitCustomTask({
                answer: this.answer,
                id: this.id,
                imageUrl: this.urlList.join(',')
            })
            if (res.code == 200) {
                this.$message.success('提交成功')
                this.qxModal = false;
                this.queryStudentTaskInfo(this.id)
            }
        },
    }
}
</script>
<style scoped lang="less">
header {
    width: 100%;
    height: .92rem;
}

.workDetail {
    background: #f5f6f8;
    width: 100%;
    padding-top: .3rem;
    box-sizing: border-box;
    overflow: hidden;
}

.btn2 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: .3rem;

    .qx {
        width: 1.43rem;
        height: .48rem;
        border: .01rem solid #DDDDDD;
        border-radius: .24rem;
        font-size: .18rem;
        font-family: 'PingFang SC';
        font-weight: 400;
        color: #36393D;
        text-align: center;
        line-height: .48rem;
        margin: 0 .15rem;
        cursor: pointer;
        transition: filter 0.3s;

        &:hover {
            filter: brightness(1.01);
        }
    }

    .qd {
        width: 1.43rem;
        height: .48rem;
        background: linear-gradient(90deg, #4C88EB, #3D70E7);
        border-radius: .24rem;
        font-size: .18rem;
        font-family: 'PingFang SC';
        font-weight: 400;
        color: #FFFFFF;
        text-align: center;
        margin: 0 .15rem;
        cursor: pointer;
        transition: filter 0.3s;

        &:hover {
            filter: brightness(1.1);
        }
    }
}

.content {
    width: 14.8rem;
    margin: auto;
    padding: .23rem .5rem .5rem .5rem;
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: 0px 0px .2rem 0px rgba(0, 0, 0, 0.16);
    border-radius: .16rem;
    overflow: hidden;
    position: relative;

    .back {
        font-size: .18rem;
        font-family: PingFang SC;
        font-weight: 400;
        color: #979EA7;
        display: flex;
        align-items: center;
        cursor: pointer;

        img {
            width: .2rem;
            height: .2rem;
            margin-right: .08rem;
        }
    }

    .section {
        .top {
            height: 1.19rem;
            width: 100%;
            margin-top: .4rem;
            border-bottom: .01rem solid #eee;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .left {
                .name {
                    font-size: .2rem;
                    font-family: PingFang SC;
                    font-weight: 500;
                    color: #36393D;
                }

                .jiezhi {
                    margin-top: .18rem;
                    font-size: .16rem;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #6D737A;
                }
            }

            .right {
                .status1 {
                    width: .7rem;
                    height: .32rem;
                    line-height: .32rem;
                    background: linear-gradient(-27deg, #FF6000 0%, #FF7800 100%);
                    border-radius: .1rem;
                    font-size: .16rem;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    text-align: center;
                }

                .status2 {
                    width: .7rem;
                    height: .32rem;
                    line-height: .32rem;
                    background: #0DC712;
                    border-radius: .1rem;
                    font-size: .16rem;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    text-align: center;
                }
            }
        }

        .detail {
            border-bottom: .01rem solid #eee;
            padding-bottom: .2rem;
            overflow: hidden;

            .list {
                .title {
                    font-size: .2rem;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #36393D;
                    margin: .3rem 0;
                }

                .image_ {
                    float: left;
                    width: 1.5rem;
                    height: 1.5rem;
                    background: #E7E7E7;
                    border-radius: 10px;
                    margin-right: .2rem;
                    cursor: pointer;
                }
            }
        }

        .t {
            font-size: .2rem;
            font-family: PingFang SC;
            font-weight: 400;
            color: #36393D;
            margin: .3rem 0 .2rem;
        }

        .textarea {
            width: 100%;
            overflow: auto;
            word-break: break-all;
            background-color: #DFE8F6;
            background: #F7F8F9;
            border-radius: .06rem;
        }
    }

    .btn {

        width: 4.4rem;
        height: .72rem;
        line-height: .72rem;
        background: linear-gradient(90deg, #4C88EB, #3D70E7);
        border-radius: .36rem;
        font-size: .2rem;
        text-align: center;
        color: #FFFFFF;
        margin: .5rem auto 0;
        cursor: pointer;
        transition: filter 0.3s;

        &:hover {
            filter: brightness(1.1);
        }
    }
}
</style>

