import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home.vue'
import CourseList from '../views/courseList.vue'
import LessonDetail from '../views/lessonDetail.vue'
import LessonInfo from '../views/lessonInfo.vue'
import Login from '../views/login.vue'
import Register from '../views/register.vue'
import Repwd from '../views/repwd.vue'
import UserCenter from '../views/userCenter.vue'
import MyCollect from '../views/myCollect.vue'
import MyCollectList from '../views/myCollectList.vue'
import WorkDetail from '../views/workDetail.vue'
import BeginDailyWork from '../views/beginDailyWork.vue'
import ExamDeatil from '../views/examDeatil.vue'
import BeginExam from '../views/beginExam.vue'
import BeginWork from '../views/beginWork.vue'
import Search from '../views/search.vue'



Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/courseList',
    name: 'courseList',
    component: CourseList
  },
  {
    path: '/beginWork',
    name: 'beginWork',
    component: BeginWork
  },
  {
    path: '/search',
    name: 'search',
    component: Search
  },
  {
    path: '/myCollect',
    name: 'myCollect',
    component: MyCollect
  },
  {
    path: '/workDetail',
    name: 'workDetail',
    component: WorkDetail
  },
  {
    path: '/examDeatil',
    name: 'examDeatil',
    component: ExamDeatil
  },
  {
    path: '/beginExam',
    name: 'beginExam',
    component: BeginExam
  },
  {
    path: '/beginDailyWork',
    name: 'beginDailyWork',
    component: BeginDailyWork
  },
  {
    path: '/myCollectList',
    name: 'myCollectList',
    component: MyCollectList
  },
  {
    path: '/lessonDetail',
    name: 'lessonDetail',
    component: LessonDetail
  },
  {
    path: '/lessonInfo',
    name: 'lessonInfo',
    component: LessonInfo
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/register',
    name: 'register',
    component: Register
  },
  {
    path: '/repwd',
    name: 'repwd',
    component: Repwd
  },
  {
    path: '/userCenter',
    name: 'userCenter',
    component: UserCenter
  },
]

const router = new VueRouter({
  routes
})

export default router
