<template>
  <div class="container">
    <div class="left">
      <div class="logo" @click="$router.push({ path: '/' }, onComplete => { }, onAbort => { })">
        电工课程平台
      </div>

      <div class="nav">
        <div :class="active == 1 ? 'nav_item active' : 'nav_item'"
          @click="$router.push({ path: '/' }, onComplete => { }, onAbort => { })">首页</div>
        <div :class="active == 2 ? 'nav_item active' : 'nav_item'" @click="toCourseList">课程列表</div>
      </div>
    </div>
    <div class="right">
      <div class="searchBox">
        <input type="text" class="search" placeholder="搜索感兴趣的课程" v-model="keyWord" @keyup.enter="search()">
        <img src="../static/img/icon_search.png" class="fd" @click="search()">
      </div>
      <div @click.stop="checkLogin">
        <el-popover placement="bottom" trigger="click" popper-class="popoverStyle">
          <div class="myTask" slot="reference">
            <img src="../static/img/icon_db.png" class="db">
            <span>我的待办</span>
            <div class="point" v-if="list.length != 0">{{ list.length }}</div>
          </div>
          <div class="taskPop">
            <div class="title">我的待办 <div class="point">{{ list.length }}</div>
            </div>
            <div class="line1"></div>
            <div class="line2"></div>
            <div class="list" :style="list.length >= 4 ? 'overflow-y: scroll' : ''" v-if="list.length > 0">
              <div class="list_item" v-for="item, index in list" :key="index">
                <div class="list_left">
                  <div class="top"><span :title="item.taskName">{{ item.taskName }}</span>
                    <div class="yuqi" v-if="item.overtime">逾期未提交</div>
                  </div>
                  <div class="bottom" v-if="item.type != 1">截止时间：{{ item.endTime }}</div>
                </div>
                <div class="list_right" v-if="!item.overtime">
                  <span v-if="item.type != 0" @click.stop="beginTask(item.id, item.type)">开始作业</span>
                  <span v-else @click.stop="beginExam(item.id)">开始考试</span>
                </div>
              </div>
            </div>
            <h3 style="margin-top: .17rem;" v-else>暂无待办</h3>
          </div>
        </el-popover>
      </div>
      <div class="userCenter" @click="toUserCenter">
        <img :src="'https://eeapi.appcloud.tech' + $store.state.userInfo.headImg" class="head"
          v-if="$store.state.userInfo && $store.state.userInfo.headImg">
        <img src="../static/img/head.png" class="head" v-else>
        <span>个人中心</span>
      </div>
    </div>
  </div>
</template>

<script>
import { getToken } from '@/api/auth'
import { queryWaitFinishTask } from '@/api/home'
export default {
  name: 'homeHeader',
  data() {
    return {
      keyWord: '',
      list: [],
      isLogin: false
    }
  },
  props: {
    type: Number,
    active: Number
  },
  created() {
    let userInfo = localStorage.getItem('eduUserInfo');
    if (userInfo) {
      userInfo = JSON.parse(userInfo)
      this.$store.dispatch('SET_USER_INFO', userInfo)
    }
  },
  mounted() {
    this.keyWord = this.$route.query.keyWord ? this.$route.query.keyWord : ''
    let token = getToken()
    if (token) {
      this.queryWaitFinishTask();
      this.isLogin = true
    } else {
      this.isLogin = false;
      this.$store.dispatch('CLEAR')
      localStorage.removeItem('eduUserInfo')
    }
  },
  methods: {
    toCourseList() {
      if (!this.isLogin) {
        this.$message.error('请先登录')
        setTimeout(() => {
          this.$router.push({ path: '/login' }, onComplete => { }, onAbort => { })
        }, 1500);
      } else {
        this.$router.push({ path: '/courseList' }, onComplete => { }, onAbort => { })
      }
    },
    toUserCenter() {
      if (!this.isLogin) {
        this.$message.error('请先登录')
        setTimeout(() => {
          this.$router.push({ path: '/login' }, onComplete => { }, onAbort => { })
        }, 1500);
      } else {
        this.$router.push({ path: '/userCenter' }, onComplete => { }, onAbort => { })
      }
    },
    checkLogin() {
      if (!this.isLogin) {
        this.$message.error('请先登录')
        setTimeout(() => {
          this.$router.push({ path: '/login' }, onComplete => { }, onAbort => { })
        }, 1500);
      }
    },
    search() {
      this.$router.push({
        path: '/search',
        query: {
          keyWord: this.keyWord
        }
      },
        onComplete => {
          if (this.$route.path == '/search') {
            window.location.reload()
          }
        },
        onAbort => { })
    },
    async queryWaitFinishTask() {
      let res = await queryWaitFinishTask();
      this.list = res.data;
    },
    beginTask(id, type) {
      if (type == 2) {
        this.$router.push({
          path: '/beginWork',
          query: {
            id,
            type
          }
        },
          onComplete => {
            if (this.$route.path == '/beginWork') {
              window.location.reload()
            }
          },
          onAbort => { }
        )
      } else if (type == 1 || type == 3) {
        this.$router.push({
          path: '/workDetail', query: {
            id,
            type
          }
        },
          onComplete => {
            if (this.$route.path == '/workDetail') {
              window.location.reload()
            }
          },
          onAbort => { }
        )
      }
    },
    beginExam(id) {
      this.$router.push({
        path: '/examDeatil', query: {
          id,
          type: 'exam'
        }
      },
        onComplete => {
          if (this.$route.path == '/examDeatil') {
            window.location.reload()
          }
        },
        onAbort => { }
      )
    },
  },
}
</script>
<style>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.el-popover.popoverStyle {
  padding: 0;
  border: 0;
  box-shadow: 0;
  -webkit-box-shadow: 0;
  background: transparent;
}
</style>
<style scoped lang="less">
.taskPop {
  width: 5.5rem;
  // height: 5.5rem;
  background: #FFFFFF;
  box-shadow: 0 0 .2rem 0 rgba(0, 0, 0, 0.16);
  border-radius: .16rem;
  padding: .4rem .3rem;

  .title {
    font-size: .22rem;
    font-family: 'PingFang SC';
    font-weight: 500;
    color: #36393D;
    margin-bottom: .17rem;
    display: flex;
    align-items: center;

    .point {
      width: .2rem;
      height: .2rem;
      background: #F20000;
      border-radius: 50%;
      margin-left: .1rem;
      font-size: .14rem;
      font-family: 'PingFang SC';
      font-weight: 400;
      color: #FFFFFF;
      line-height: .2rem;
      text-align: center;
    }
  }

  .list {
    max-height: 4.69rem;

    .list_item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: .29rem 0;
      border-bottom: .01rem solid #eee;

      .list_left {
        .top {
          display: flex;
          align-items: center;
          font-size: .18rem;
          font-family: 'PingFang SC';
          font-weight: 700;
          color: #36393D;

          span {
            max-width: 60%;
            word-break: break-all;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
          }

          .yuqi {
            width: .9rem;
            height: .24rem;
            background: linear-gradient(90deg, RGBA(255, 120, 0, 1), RGBA(255, 99, 0, 1));
            border-radius: .1rem;
            font-size: .14rem;
            line-height: .24rem;
            text-align: center;
            font-family: 'PingFang SC';
            font-weight: 400;
            color: #FFFFFF;
            margin-left: .1rem;
          }
        }

        .bottom {
          font-size: .16rem;
          font-family: 'PingFang SC';
          font-weight: 400;
          color: #6D737A;
          margin-top: .18rem;
        }
      }

      .list_right {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;


        span {
          width: 1.33rem;
          height: .48rem;
          background: #000;
          cursor: pointer;
          width: 1.33rem;
          height: .48rem;
          background: linear-gradient(90deg, #4C88EB, #3D70E7);
          border-radius: .36rem;
          font-size: .18rem;
          color: #FFFFFF;
          transition: filter 0.3s;
          text-align: center;
          line-height: .48rem;

          &:hover {
            filter: brightness(1.1);
          }
        }
      }
    }
  }

  .line1 {
    width: .87rem;
    height: .03rem;
    background: #4C88EB;
  }

  .line2 {
    width: 100%;
    height: .01rem;
    background: #eee;
  }
}


.container {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  height: .92rem;
  padding: 0 1.15rem;
  background-image: url(../static/img/top_bg.png);
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  // justify-content: space-between;
  white-space: nowrap;


  .left {
    height: 100%;
    display: flex;
    align-items: center;

    .logo {
      cursor: pointer;
      font-family: 'AlimamaShuHeiTi';
      font-size: .32rem;
      font-weight: bold;
      color: #FFFFFF;
    }

    .nav {
      display: flex;
      margin-left: .97rem;

      .nav_item {
        font-size: .22rem;
        color: rgba(255, 255, 255, 0.6);
        font-family: 'PingFang SC';
        font-weight: 500;
        margin-right: .6rem;
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: color 0.3s;

        &:hover {
          color: #fff;
        }

        img {
          margin-left: .14rem;
          margin-top: .04rem;
          width: .17rem;
          height: .1rem;
          opacity: 0.6;
        }
      }

      .nav_item.active {
        position: relative;
        color: #fff;

        &::after {
          content: '';
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: -0.12rem;
          width: .22rem;
          height: .03rem;
          background: #FFFFFF;
          border-radius: .02rem;
        }
      }
    }

  }

  .right {
    display: flex;
    align-items: center;
    margin-left: 1.88rem;

    .searchBox {
      position: relative;
      width: 5.4rem;
      height: .52rem;

      input {
        width: 5.4rem;
        height: .52rem;
        font-size: .18rem;
        background: #FFFFFF;
        border-radius: .26rem;
        border: 0;
        outline: 0;
        padding-left: .3rem;
        padding-right: .6rem;
        box-sizing: border-box;

        &::placeholder {
          color: #999;
        }
      }

      .fd {
        position: absolute;
        z-index: 999;
        width: .3rem;
        height: .3rem;
        right: .28rem;
        top: 50%;
        transform: translateY(-50%);
        color: #4C88EB;
        cursor: pointer;
      }
    }

    .myTask {
      display: flex;
      align-items: center;
      position: relative;
      margin-left: .47rem;

      .db {
        width: .26rem;
        height: .26rem;
        margin-right: .07rem;
      }

      span {
        font-size: .22rem;
        font-family: 'PingFang SC';
        font-weight: 400;
        color: #FFFFFF;
        cursor: pointer;
        transition: filter 0.3s;

        &:hover {
          filter: brightness(1.1);
        }
      }

      .point {
        width: .2rem;
        height: .2rem;
        background: #F20000;
        border-radius: 50%;
        text-align: center;
        line-height: .2rem;
        font-size: .14rem;
        font-family: 'PingFang SC';
        font-weight: 400;
        color: #FFFFFF;
        position: absolute;
        right: -0.11rem;
        top: -0.08rem;
      }
    }


    .userCenter {
      display: flex;
      align-items: center;
      position: relative;
      margin-left: .8rem;
      cursor: pointer;

      .head {
        width: .44rem;
        height: .44rem;
        background: #000000;
        border: .02rem solid #FFFFFF;
        border-radius: 50%;
        margin-right: .14rem;
      }

      span {
        font-size: .22rem;
        font-family: 'PingFang SC';
        font-weight: 400;
        color: #FFFFFF;
        transition: filter 0.3s;

        &:hover {
          filter: brightness(1.1);
        }
      }
    }
  }
}
</style>
