<template>
  <div style="width: 100%;" class="container">
    <div class="content">
      <div class="left"></div>
      <div class="right">
        <div class="nav">
          - 欢迎登录 -
        </div>
        <p class="msg">审核通过的学生才可登录</p>
        <div class="loginForm">
          <el-form :model="form1" :rules="rules" ref="ruleForm" class="demo-ruleForm">
            <el-form-item label="" prop="loginName">
              <div class="formItem">
                <img src="../static/img/icon_sjh.png">
                <input type="text" placeholder="请输入手机号" v-model="form1.loginName">
              </div>
            </el-form-item>
            <el-form-item label="" prop="loginPwd">
              <div class="formItem">
                <img src="../static/img/icon_mm.png">
                <input type="password" placeholder="请输入密码" v-model="form1.loginPwd" @keyup.enter="handleLogin()">
              </div>
            </el-form-item>
          </el-form>
        </div>
        <el-button class="btn" type="primary" @click="handleLogin()" :loading="loading">
          <span>登录</span>
        </el-button>
        <div class="footer">
          <span @click="$router.push({ path: '/register' }, onComplete => { }, onAbort => { })">点击注册</span>
          <span @click="$router.push({ path: '/repwd' }, onComplete => { }, onAbort => { })">忘记密码？</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { login } from "@/api/login";
import { getLoginStudentInfo } from "@/api/mine";
export default {
  data() {
    return {
      loading: false,
      form1: {
        loginName: '',
        loginPwd: ''
      },
      rules: {
        loginName: [
          { required: true, message: '请输入手机号', trigger: ['blur', 'change'] }
        ],
        loginPwd: [
          { required: true, message: '请输入密码', trigger: ['blur', 'change'] },
        ]
      },
    }
  },
  mounted() {
  },
  methods: {
    handleLogin() {
      this.$refs['ruleForm'].validate(async (valid) => {
        if (valid) {
          try {
            this.loading = true;
            let res = await login({
              loginName: this.form1.loginName,
              loginPwd: this.form1.loginPwd
            });
            this.loading = false;
            this.$store.dispatch('SET_TOKEN', res.data.token)
            if (res.code == 200) {
              let res2 = await getLoginStudentInfo({});
              localStorage.setItem('eduUserInfo', JSON.stringify(res2.data))
              this.$router.push({
                path: '/'
              })
            }
          } catch (error) {
            this.loading = false;
          }

        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
}
</script>
<style>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: Microsoft YaHei;
}
</style>
<style lang="less" scoped>
.container {
  width: 100%;
  height: 100vh;
  background-image: url(../static/img/bgi.png);
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .content {
    width: 13.35rem;
    height: 7.5rem;
    background: #FFFFFF;
    box-shadow: 0rem 0rem 1.14rem 0rem #D2D2D2;
    border-radius: .2rem;
    display: flex;
    padding: .31rem;
    box-sizing: border-box;

    .left {
      width: 6.8rem;
      height: 6.8rem;
      background-image: url(../static/img/leftBG.png);
      background-size: 100% 100%;
    }

    .right {
      height: 100%;
      width: 4.4rem;
      margin-left: 1rem;

      .nav {
        font-size: .34rem;
        font-family: 'PingFang SC';
        font-weight: 500;
        color: #4C88EB;
        text-align: center;
        margin-top: 1.1rem;
        user-select: none;
      }

      .msg {
        font-size: .16rem;
        font-family: 'PingFang SC';
        font-weight: 400;
        color: #6D737A;
        margin: .29rem 0 .57rem;
        text-align: center;
        user-select: none;
      }

      .loginForm {
        // margin-top: .47rem;
        // padding: 0 15.2%;
        overflow: hidden;

        .formItem {
          position: relative;

          img {
            position: absolute;
            left: .26rem;
            top: 50%;
            transform: translateY(-50%);
          }

          input {
            width: 4.4rem;
            height: .72rem;
            background: #F7F8F9;
            border-radius: .1rem;
            border: 0;
            outline: 0;
            padding-left: .67rem;
            padding-right: .26rem;
            font-size: .18rem;
            margin-top: .08rem;

            &::placeholder {
              color: #BEC2C7;
            }
          }
        }
      }

      .btn {
        cursor: pointer;
        width: 4.4rem;
        height: .72rem;
        background: linear-gradient(90deg, #4C88EB, #3D70E7);
        border-radius: .36rem;
        font-size: .2rem;
        text-align: center;
        color: #FFFFFF;
        margin-top: .1rem;
        transition: filter 0.3s;

        &:hover {
          filter: brightness(1.1);
        }
      }

      .footer {
        margin-top: .3rem;
        font-size: .18rem;
        color: #4C88EB;
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
          transition: all 0.3s;

          &:hover {
            filter: brightness(1.1);
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
