<template>
    <div style="width: 100%;" class="home">
        <header>
            <homeHeader :active="1" />
        </header>
        <div class="content">
            <div class="title">
                <img src="../static/img/icon_kclb.png">
                <span>最新课程</span>
            </div>
            <div class="list">
                <div class="list_item" v-for="item, index in newSubjectList" :key="index" @click="toDetail(item.id)">
                    <img :src="'https://eeapi.appcloud.tech' + item.imageUrl" class="photo">
                    <div>
                        <div class="name">{{ item.name }}</div>
                        <div class="teacher">{{ item.teacherNames }}老师</div>
                        <div class="text">
                            <div class="left">已更新{{ item.chapterNum }}小节</div>
                        </div>
                    </div>
                </div>
                <div style="clear:both;"></div>
            </div>
            <div class="title">
                <img src="../static/img/icon_kclb.png">
                <span>热门课程</span>
            </div>
            <div class="list">
                <div class="list_item" v-for="item, index in hotSubjectList" :key="index">
                    <img :src="'https://eeapi.appcloud.tech' + item.imageUrl" class="photo">
                    <div>
                        <div class="name">{{ item.name }}</div>
                        <div class="teacher">{{ item.teacherNames }}老师</div>
                        <div class="text">
                            <div class="left">已更新{{ item.chapterNum }}小节</div>
                        </div>
                    </div>
                </div>
                <div style="clear:both;"></div>
            </div>
        </div>
        <homeFooter />
    </div>
</template>

<script>
import { getToken } from '@/api/auth'
import homeHeader from '@/components/homeHeader.vue'
import homeFooter from '@/components/homeFooter.vue'
import { queryRecommSubject } from "@/api/home";
export default {
    components: {
        homeHeader, homeFooter
    },
    data() {
        return {
            newSubjectList: [],
            hotSubjectList: [],
            isLogin: false,
        }
    },
    mounted() {
        this.queryRecommSubject()
        let token = getToken()
        if (token) {
            this.isLogin = true
        } else {
            this.isLogin = false
        }
    },
    beforeDestroy() {
    },
    methods: {
        toDetail(id) {
            if (!this.isLogin) {
                this.$message.error('请先登录')
                setTimeout(() => {
                    this.$router.push({ path: '/login' }, onComplete => { }, onAbort => { })
                }, 1500);
            } else {
                this.$router.push({
                    path: '/lessonDetail',
                    query: { id }
                })
            }
        },
        async queryRecommSubject() {
            let res = await queryRecommSubject({})
            if (res.code == 200) {
                this.newSubjectList = res.data.newSubjectList;
                this.hotSubjectList = res.data.hotSubjectList;
            }
        }
    }
}
</script>
<style scoped lang="less">
header {
    width: 100%;
    height: .92rem;
}

.home {
    background: #f5f6f8;
    width: 100%;
}

.content {
    width: 14.78rem;
    margin: auto;
    padding-bottom: .5rem;

    .title {
        display: flex;
        align-items: center;
        margin: .39rem 0;

        img {
            width: .4rem;
            height: .4rem;
            margin-right: .07rem;
        }

        span {

            font-size: .3rem;
            font-family: 'pangmen';
            font-weight: 400;
            color: #2D2D2D;
        }
    }

    .list {

        .list_item {
            width: 3.47rem;
            height: 3.6rem;
            background: #FFFFFF;
            box-shadow: 0 0 .2rem 0 rgba(0, 0, 0, 0.3);
            border-radius: .1rem;
            display: inline-block;
            float: left;
            margin-right: .3rem;
            margin-bottom: .3rem;
            overflow: hidden;
            position: relative;
            cursor: pointer;
            transition: transform .3s;

            &:hover {
                transform: scale(1.03);
            }

            &:nth-child(4n) {
                margin-right: 0;
            }

            .photo {
                width: 100%;
                height: 1.96rem;
                background: #eee;
            }

            >div {
                padding: .19rem .2rem;
                box-sizing: border-box;
                width: 100%;

                .name {
                    font-size: .2rem;
                    font-family: 'PingFang SC';
                    font-weight: 700;
                    color: #36393D;
                }

                .teacher {
                    font-size: .16rem;
                    font-family: 'PingFang SC';
                    font-weight: 400;
                    color: #6D737A;
                    margin: .13rem 0 .13rem;
                }

                .text {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .left {
                        font-size: .16rem;
                        font-family: 'PingFang SC';
                        font-weight: 400;
                        color: #4C88EB;
                    }

                    .right {
                        font-size: .16rem;
                        font-family: OPPOSans;
                        font-weight: 800;
                        color: #FF7800;
                    }
                }

                .bottom {
                    position: absolute;
                    left: .2rem;
                    bottom: .2rem;
                    display: flex;
                    align-items: center;

                    img {
                        width: .162rem;
                        height: .162rem;
                        margin-right: .07rem;
                    }

                    font-size: .16rem;
                    font-family: 'PingFang SC';
                    font-weight: 400;
                    color: #979EA7;
                }
            }
        }
    }
}
</style>

